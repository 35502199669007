import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";
import store from "../selectors/store";

export const createSupplierAccessoriesPayment = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.SUPPLIER_ACCESSORIES_PAYMENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error;
    }
};

export const getSupplierAccessoriesPayment = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.ALLSUPPLIER_ACCESSORIES_PAYMENT}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const getSupplierAccessoriesPaymentById = async (billNo) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.SUPPLIER_ACCESSORIES_PAYMENT}?bill_no=${billNo}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};