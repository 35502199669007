// component/home.jsx
import React from "react";
import { Layout } from "antd";
import SideBar from "../../components/common/supplierSideBar.jsx";
import AppHeader from "../../components/common/Heder.jsx";
import CreateSupplierAccessoriesBill from "../../components/supplierAccessories/create_bill_accessories.jsx";

const { Content } = Layout;

const SupplierLastBillPage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <SideBar />
      <Layout>
        <AppHeader />
        <Content>
          <CreateSupplierAccessoriesBill />
        </Content>
      </Layout>
    </Layout>
  );
}

export default SupplierLastBillPage;
