import React, { useState } from "react";
import { Menu } from "antd";
import {
    PieChartOutlined,
    UnorderedListOutlined,
    MailOutlined,
    ShoppingCartOutlined,
    FolderOpenOutlined,
    UserOutlined,
    DollarOutlined,
    HomeOutlined,
    PlusCircleOutlined,
    DatabaseOutlined,
    FieldTimeOutlined
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { Link } from "react-router-dom";
import logo from "../../images/product/logo.png";

const getMenu = (label, key, icon, children) => {
    return {
        label,
        key,
        icon,
        children,
    };
}

const items = [
    getMenu(<Link to='/home'>Home</Link>, '1', <HomeOutlined />,),
    getMenu(<Link to='/sales'>Sales</Link>, '2', <MailOutlined />),
    getMenu('Product', 'sub1', <ShoppingCartOutlined />, [
        getMenu(<Link to='/allproduct'>List</Link>, '3', <UnorderedListOutlined />),
        getMenu(<Link to='/product'>Create</Link>, '4', <PlusCircleOutlined />),
    ]),
    getMenu('Category', 'sub2', <FolderOpenOutlined />, [
        getMenu(<Link to='/allcategory'>List</Link>, '5', <UnorderedListOutlined />),
        getMenu(<Link to='/category'>Create</Link>, '6', <PlusCircleOutlined />),
    ]),
    getMenu('Style', 'sub3', <FolderOpenOutlined />, [
        getMenu(<Link to='/allstyle'>List</Link>, '7', <UnorderedListOutlined />),
        getMenu(<Link to='/style'>Create</Link>, '8', <PlusCircleOutlined />),
    ]),
    getMenu('Style Category', 'sub4', <PieChartOutlined />, [
        getMenu(<Link to='/allstyle_category'>List</Link>, '9', <UnorderedListOutlined />),
        getMenu(<Link to='/style_category'>Create</Link>, '10', <PlusCircleOutlined />),
    ]),
    getMenu('Cheque', 'sub6', <DollarOutlined />, [
        getMenu(<Link to='/allcheque'>List</Link>, '11', <DatabaseOutlined />),
        getMenu(<Link to='/chequeReturn'>Return</Link>, '12', <FieldTimeOutlined />),
        //getMenu(<Link to='/customer'>Create</Link>, '17', <PlusCircleOutlined />),
    ]),getMenu('Customer Account', 'sub7', <UserOutlined />, [
        getMenu(<Link to='/allcustomer_account'>Summery</Link>, '13', <DatabaseOutlined />),
        getMenu(<Link to='/customer_account_limit'>Limit</Link>, '14', <FieldTimeOutlined />),
        //getMenu(<Link to='/customer'>Create</Link>, '17', <PlusCircleOutlined />),
    ]),getMenu('Customer', 'sub8', <UserOutlined />, [
        getMenu(<Link to='/allcustomer'>List</Link>, '15', <UnorderedListOutlined />),
        getMenu(<Link to='/customer'>Create</Link>, '16', <PlusCircleOutlined />),
    ]),

];

const SideBar = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ paddingTop: '15px' }}>
            <div className="demo-logo-vertical">
                <div className="logo">
                    <img alt="Logo" src={logo} style={{paddingLeft: '20px'}}/>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    items={items}
                />
            </div>
        </Sider>
    )
};

export default SideBar;