// component/home.jsx
import React from "react";
import { Layout } from "antd";
import TransportSideBar from "../../components/common/transportSideBar.jsx";
import AppHeader from "../../components/common/Heder.jsx";
import ViewProductReceivedAll from "../../components/product_received/viewAllRecord.jsx";

const { Content } = Layout;

const ProductReceivedAllPage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <TransportSideBar />
      <Layout>
        <AppHeader />
        <Content>
          <ViewProductReceivedAll />
        </Content>
      </Layout>
    </Layout>
  );
}

export default ProductReceivedAllPage;
