import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Table } from "antd";
import { getProduct, updateProduct, deleteProduct } from "../../store/api/product";
import { DeleteOutlined } from '@ant-design/icons';
import '../../styles/new.scss';
import { getCategory } from "../../store/api/category";
import Search from "antd/es/input/Search";

const ViewProduct = () => {
    const [form] = Form.useForm();
    const [product, setProduct] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [modalVisibleCategory, setModalVisibleCategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [search, setSearch] = useState('');

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchProduct();
        fetchCategories();
    }, []);

    const fetchProduct = async () => {
        try {
            const response = await getProduct();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const productData = response.data.data.map((item) => ({
                        ...item,
                        key: item.p_id,
                    }));
                    setProduct(productData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setProduct([]);
                }
            } else {
                console.error('Failed to fetch product');
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedProduct(null);
        setIsModalVisible(false);
    };

    /*
    const handleEdit = (product) => {
        setSelectedProduct(product);
        setIsModalVisible(true);
        form.setFieldsValue(product);
    };
    */

    const handleDelete = async (product) => {
        try {
            const response = await deleteProduct(product.p_id);

            if (response.status === 200) {
                setProduct((prevProduct) =>
                    prevProduct.filter((item) => item.p_id !== product.p_id)
                );
            } else {
                console.error('Failed to delete product');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateProduct(selectedProduct.p_id, values);

            if (response.status === 200) {
                setProduct((prevProduct) =>
                    prevProduct.map((item) =>
                        item.p_id === selectedProduct.p_id ? { ...item, ...values } : item
                    )
                );

                form.resetFields();
                setSelectedProduct(null);
                setIsModalVisible(false);
                window.location.reload();
            } else {
                console.error('Failed to update product');
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Retail',
            dataIndex: 'r_price',
            key: 'r_price',
        },
        {
            title: 'Wholesale',
            dataIndex: 'h_price',
            key: 'h_price',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, product) => (
                <div>
                    {/** <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(product)}
                    /> */}
                    <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(product)}
                    />
                </div>
            ),
        },
    ];

    const onRowClick = (record) => {
        //const categoryId = record.p_id;
        // navigateTo(`/tournament?categoryId=${categoryId}`);
    };

    const fetchCategories = async () => {
        try {
            const response = await getCategory();

            if (response.status === 200) {
                setCategories(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching categories", error);
        }
    };

    const handleCategorySelect = (c_id) => {
        setSelectedCategory(c_id);
        setModalVisibleCategory(false);
        form.setFieldsValue({ category: c_id });
        console.log(selectedCategory);
    };

    const categoryColumns = [
        {
            title: "Category ID",
            dataIndex: "c_id",
            key: "c_id",
        },
        {
            title: "Category",
            dataIndex: "name",
            key: "name",
        },
    ];

    const onRowClickCategory = (record) => {
        const categoryId = record.c_id;
        handleCategorySelect(categoryId);
    };

    const handleSearch = (value) => {
        setSearch(value);
    };

    const filteredSearch = product.filter(item =>
        item.category.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Category"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredSearch}
                    columns={columns}
                    scroll={{ x: true, y: 320 }}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => onRowClick(record),
                    })}
                />

            <div className="modal-container">
                <Modal
                    title="Product Details"
                    open={isModalVisible}
                    onOk={handleModalSubmit}
                    onCancel={handleModalCancel}
                    okText="Save"
                    cancelText="Cancel"
                >
                    <Form form={form} layout="vertical">

                        <Form.Item
                            name="p_id"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={() => setModalVisibleCategory(true)}>Category</Button>
                        </Form.Item>

                        <Form.Item
                            name="category"
                            label="Category Id"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="qty"
                            label="Quantity"
                            rules={[
                                { required: true, message: 'Please Enter Quantity.' },
                            ]}
                        >
                            <Input
                                placeholder="Quantity"
                            />
                        </Form.Item>

                        <Form.Item
                            name="r_price"
                            label="Retail Price"
                            rules={[
                                { required: true, message: 'Please Enter Retail Price.' },
                            ]}
                        >
                            <Input
                                placeholder="Retail Price"
                            />
                        </Form.Item>

                        <Form.Item
                            name="h_price"
                            label="Wholesale Price"
                            rules={[
                                { required: true, message: 'Please Enter Wholesale Price.' },
                            ]}
                        >
                            <Input
                                placeholder="Wholesale Price"
                            />
                        </Form.Item>

                        <div className="modal-container">
                            <Modal
                                title="Select a Category"
                                open={modalVisibleCategory}
                                onCancel={() => setModalVisibleCategory(false)}
                                footer={null}
                            >

                                <div className="table-container">
                                    <Table
                                        columns={categoryColumns}
                                        dataSource={categories}
                                        rowKey="c_id"
                                        onRow={(record) => ({
                                            onClick: () => onRowClickCategory(record),
                                        })}
                                    />
                                </div>
                            </Modal>
                        </div>

                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default ViewProduct;