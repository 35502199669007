import React, { useEffect, useState } from "react";
import { Form, Input, Table } from "antd";
import '../../styles/default.scss';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { getSupplierAccessoriesById } from "../../store/api/supplier_accessories";
import { getSupplierAccessoriesPaymentById } from "../../store/api/supplier_accessories_payment";

const ViewBill = () => {

    const [supplierAccessories, setSupplierAccessories] = useState([]);
    const [supplierAccessoriesPayment, setSupplierAccessoriesPayment] = useState('');
    const [supplier, setSupplier] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [date, setDate] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bill_no = queryParams.get('bill_no');

    useEffect(() => {
        fetchAccessoriesSupplierBill(bill_no);
        fetchAccessoriesSupplierPayment(bill_no, supplierAccessoriesPayment);
    }, [bill_no, supplierAccessoriesPayment]);

    const fetchAccessoriesSupplierBill = async (bill_no) => {
        try {
            const response = await getSupplierAccessoriesById(bill_no);
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const accessoriesSupplierData = response.data.data.map((item) => ({
                        ...item,
                        key: item.sup_acc_id,
                        date: moment(item.ch_date).format("YYYY-MM-DD"),
                        u_price: item.u_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    }));
                    setSupplierAccessories(accessoriesSupplierData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setSupplierAccessories([]);
                }
            } else {
                console.error('Failed to fetch style');
            }
        } catch (error) {
            console.error('Error fetching style:', error);
        }
    };

    const fetchAccessoriesSupplierPayment = async (bill_no, supplierAccessoriesPayment) => {
        try {
            const response = await getSupplierAccessoriesPaymentById(bill_no);
            if (response.status === 200) {

                const item = response.data.data;

                const accessoriesSupplierData = {
                    ...item,
                    key: item.sup_pay_id,
                    date: moment(item.ch_date).format("YYYY-MM-DD"),
                    total: item.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                };
                setSupplierAccessoriesPayment(accessoriesSupplierData);
                setDate(supplierAccessoriesPayment.date);
                setSupplier(supplierAccessoriesPayment.name);
                setTotalAmount(supplierAccessoriesPayment.total);
            } else {
                console.error('Failed to fetch style');
            }
        } catch (error) {
            console.error('Error fetching style:', error);
        }
    };

    // getSupplierAccessoriesPaymentById

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Price',
            dataIndex: 'u_price',
            key: 'u_price',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    return (
        <div className="user-form">
            <Form>
                <Form.Item
                    name="bill_no"
                >
                    <Input placeholder={bill_no}
                        disabled
                    />
                </Form.Item>

                <Form.Item
                    name="date"
                >
                    <Input placeholder={date} disabled />
                </Form.Item>

                <Form.Item
                    name="supplier"
                >
                    <Input placeholder={supplier} disabled />
                </Form.Item>

                <Table
                    dataSource={supplierAccessories}
                    columns={columns}
                    scroll={{ x: true }}
                    pagination={{ pageSize: 3 }}
                />

                <Form.Item
                    name="total"
                >
                    <Input placeholder={totalAmount} disabled />
                </Form.Item>
            </Form>
        </div>
    );
};

export default ViewBill;