import React from 'react';
import RoutesPage from './routes/route';

function App() {
  return (
    <div>
      <RoutesPage />
    </div>
  );
}

export default App;
