import React, { useEffect, useState } from "react";
import { Table } from "antd";
//import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import '../../styles/new.scss';
import { getCustomer } from "../../store/api/user";
import Search from "antd/es/input/Search";

const ViewCustomer = () => {
    // const [form] = Form.useForm();
    const [customer, setCustomer] = useState([]);
    const [searchCustomer, setSearchCustomer] = useState('');
    // const [isModalVisible, setIsModalVisible] = useState(false);
    // const [selectedStyle, setSelectedStyle] = useState(null);

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchCustomer();
    }, []);

    const fetchCustomer = async () => {
        try {
            const response = await getCustomer();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const customerData = response.data.data.map((item) => ({
                        ...item,
                        key: item.id,
                    }));
                    setCustomer(customerData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setCustomer([]);
                }
            } else {
                console.error('Failed to fetch customer');
            }
        } catch (error) {
            console.error('Error fetching customer:', error);
        }
    };

    /*
        const handleModalCancel = () => {
            form.resetFields();
            setSelectedStyle(null);
            setIsModalVisible(false);
        };
        
            const handleEdit = (style) => {
                setSelectedStyle(style);
                setIsModalVisible(true);
                form.setFieldsValue(style);
            };
        
            const handleDelete = async (style) => {
                try {
                    const response = await deleteStyle(style.s_id);
        
                    if (response.status === 200) {
                        setCustomer((prevStyle) =>
                            prevStyle.filter((item) => item.s_id !== style.s_id)
                        );
                    } else {
                        console.error('Failed to delete style');
                    }
                } catch (error) {
                    console.error('Error deleting style:', error);
                }
            };
          
        
            const handleModalSubmit = async () => {
                try {
                    const values = await form.validateFields();
                    const response = await updateStyle(selectedStyle.s_id, values);
        
                    if (response.status === 200) {
                        setCustomer((prevStyle) =>
                            prevStyle.map((item) =>
                                item.s_id === selectedStyle.s_id ? { ...item, ...values } : item
                            )
                        );
        
                        form.resetFields();
                        setSelectedStyle(null);
                        setIsModalVisible(false);
                    } else {
                        console.error('Failed to update style');
                    }
                } catch (error) {
                    console.error('Error updating style:', error);
                }
            };  */

    const columns = [
        {
            title: 'Customer',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '#',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        /*
        {
            title: 'Actions',
            key: 'actions',
            render: (_, style) => (
                <div>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(style)}
                    />
                    <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(style)}
                    />
                </div>
            ),
        },*/
    ];
    /*
        const onRowClick = (record) => {
            //const styleId = record.s_id;
            // navigateTo(`/tournament?customerId=${customerId}`);
        };
    */

    const handleSearchCustomerTransport = (value) => {
        setSearchCustomer(value);
    };

    const filteredProduct = customer.filter(item =>
        item.username.toLowerCase().includes(searchCustomer.toLowerCase())
    );


    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By customer"
                    value={searchCustomer}
                    onChange={(e) => handleSearchCustomerTransport(e.target.value)}
                    className="search-field"
                />
            </div>

            <div className="table-container">
                <Table
                    dataSource={filteredProduct}
                    columns={columns}
                    scroll={{ x: true, y: 380 }}
                    pagination={false}

                /* onRow={(record) => ({
                     onClick: () => onRowClick(record),
                 })}*/
                />
            </div>
            {/** 
                    <Modal
                        title="Style Details"
                        open={isModalVisible}
                        onOk={handleModalSubmit}
                        onCancel={handleModalCancel}
                        okText="Save"
                        cancelText="Cancel"
                    >
                        <Form form={form} layout="vertical">

                            <Form.Item
                                name="s_id"
                            >
                                <Input
                                    disabled
                                />
                            </Form.Item>

                            <Form.Item
                                name="name"
                                label="Style Name"
                                rules={[
                                    { required: true, message: 'Please Enter Name.' },
                                ]}
                            >
                                <Input
                                    placeholder="Style Name"
                                />
                            </Form.Item>

                        </Form>
                    </Modal>
                    */}
        </div>
    );
};

export default ViewCustomer;