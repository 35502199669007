import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Table } from "antd";
import { getProduct } from "../../store/api/product";
import '../../styles/sales.scss';
import Search from "antd/es/input/Search";
import { getStyleCategory_CategoryId, updateStyleCategoryQty } from "../../store/api/style_category";
import { getBillNo } from "../../store/api/bill";
import { updateCategoryQty } from "../../store/api/category";
import { createSales } from "../../store/api/sales";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ViewSales = () => {
    const [form] = Form.useForm();

    const [product, setProduct] = useState([]);
    const [searchProduct, setSearchProduct] = useState('');
    const [searchCategory, setSearchCategory] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleQty, setIsModalVisibleQty] = useState(false);
    const [price, setPrice] = useState('h_price');
    const [buttonName, setButtonName] = useState('Wholesale')

    const [styleCategory, setStyleCategory] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);

    const [billNo, setBillNo] = useState(null);

    const [salesCategoryId, setSalesCategoryId] = useState(null);
    const [salesCategory, setSalesCategory] = useState(null);
    const [salesPrice, setSalesPrice] = useState(null);
    const [categoryStyle, setCategoryStyle] = useState(null);

    const [categoryName, setCategoryName] = useState(null);
    const [styleCategoryId, setStyleCategoryId] = useState(null);
    const [categoryQty, setCategoryQty] = useState(null);


    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        fetchBillNo();

        fetchProduct();

        const fetchStyleCategory = async () => {
            if (buttonClicked === true) {
                try {
                    const response = await getStyleCategory_CategoryId(salesCategoryId);
                    if (response.status === 200) {
                        if (Array.isArray(response.data.data)) {
                            const styleCategoryData = response.data.data.map((item) => ({
                                ...item,
                                key: item.sc_id,
                            }));
                            setStyleCategory(styleCategoryData);
                        } else {
                            console.error('API response data is not an array:', response.data);
                            setStyleCategory([]);
                        }
                    } else {
                        console.error('Failed to fetch style category');
                    }
                } catch (error) {
                    console.error('Error fetching style category:', error);
                }
            };
        };

        fetchStyleCategory();

    }, [salesCategoryId, buttonClicked])

    const fetchProduct = async () => {
        try {
            const response = await getProduct();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const productData = response.data.data.map((item) => ({
                        ...item,
                        key: item.p_id,
                    }));
                    setProduct(productData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setProduct([]);
                }
            } else {
                console.error('Failed to fetch product');
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const fetchBillNo = async () => {
        try {
            const response = await getBillNo();
            if (response.status === 200) {
                if (response.data.data) {
                    const billNoData = response.data.data.bill_no;
                    setBillNo(billNoData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setBillNo([]);
                }
            } else {
                console.error('Failed to fetch product');
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: `${buttonName}`,
            dataIndex: `${price}`,
            key: `${price}`,
        },
    ];

    const changePrice = () => {
        if (price === 'h_price') {
            setPrice('r_price')
            setButtonName('Retail')
        } else {
            setPrice('h_price')
            setButtonName('Wholesale')
        }
    };

    const handleSearchProduct = (value) => {
        setSearchProduct(value);
    };

    const filteredProduct = product.filter(item =>
        item.category.toLowerCase().includes(searchProduct.toLowerCase())
    );

    const onRowClick = (record) => {
        setButtonClicked(true);
        setSalesCategoryId(record.c_id);
        setSalesCategory(record.category);
        if (price === 'h_price') {
            setSalesPrice(record.h_price);
        } else if (price === 'r_price') {
            setSalesPrice(record.r_price);
        }
        setIsModalVisible(true);
    };

    const columnsCategory = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Style',
            dataIndex: 'style',
            key: 'style',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
    ];

    const handleSearchCategory = (value) => {
        setSearchCategory(value);
    };

    const filteredCategory = styleCategory.filter(item =>
        item.category.toLowerCase().includes(searchCategory.toLowerCase())
    );

    const onRowClickQty = (record) => {
        setStyleCategoryId(record.sc_id);
        setCategoryName(record.category);
        setCategoryStyle(record.style);
        setCategoryQty(record.qty);
        setIsModalVisibleQty(true);
    };

    const handleSubmit = async (values) => {
        const { qty } = values;
        if (categoryQty < qty) {
            alert('Quantity not enough')
        } else {
            if (!isNaN(qty)) {
                const tot = salesPrice * qty;
                setStyleCategoryId(styleCategoryId);
                //console.log(styleCategoryId)
                changeStyleCategoryQty(qty);
                addSales(qty, tot);

            }
        }
    }

    /*
    useEffect(() => {
        setBillNo(billNo);
        setSalesCategoryId(salesCategoryId);
        setSalesCategory(salesCategory);
        setStyleCategoryId(styleCategoryId);
        setCategoryStyle(categoryStyle);
        setSalesPrice(salesPrice);
        setSalesTotal(salesTotal);
        setSalesFinal(salesFinal);
    }, [
        billNo,
        salesCategoryId,
        salesCategory,
        styleCategoryId,
        categoryStyle,
        salesPrice,
        salesTotal,
        salesFinal
    ]);*/

    const changeStyleCategoryQty = async (qty) => {
        try {
            const updatedData = {
                qty: qty,
            };
            const responseCategory = await updateCategoryQty(salesCategoryId, updatedData);
            const responseStyleCategory = await updateStyleCategoryQty(styleCategoryId, updatedData);
            if (responseCategory.status === 200 && responseStyleCategory.status === 200) {
                setIsModalVisibleQty(false);
                setIsModalVisible(false);
            } else {
                console.error('Failed to update category quantity');
            }
        } catch (error) {
            console.error('Error updating category quantity:', error);
        }
    }

    const addSales = async (qty, tot) => {
        await createSales({
            bill_no: billNo,
            category: salesCategory,
            style: categoryStyle,
            price: salesPrice,
            qty: qty,
            total: tot,
            discount: 0,
            final: tot
        }, token)

        form.resetFields();

    };

    const handleSalesPage = () => {
        navigateTo(`/salesBill?bill_no=${billNo}`)
    }

    /*
    const columnsSales = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Category',
            dataIndex: 'salesCategory',
            key: 'salesCategory',
        },
        {
            title: 'Style',
            dataIndex: 'categoryStyle',
            key: 'categoryStyle',
        },
        {
            title: 'Quantity',
            dataIndex: 'salesQty',
            key: 'salesQty',
        },
        {
            title: 'Price',
            dataIndex: 'salesPrice',
            key: 'salesPrice',
        },
        {
            title: 'Total',
            dataIndex: 'salesTotal',
            key: 'salesTotal',
        },
    ];

    const handleSalesTable = () => {
        const newRow = {
            key: index,
            index,
            salesCategory,
            categoryStyle,
            salesQty,
            salesPrice,
            salesTotal,
            salesFinal,
        };

        setTableData([...tableData, newRow]);
        setIndex(index + 1);
    };
*/


    return (
        <div className="user-form-sales">
            <Row>
                <Col>

                    <Search
                        placeholder="Search"
                        value={searchProduct}
                        onChange={(e) => handleSearchProduct(e.target.value)}
                        className="search-field"
                    />

                    <Button onClick={changePrice}>{buttonName}</Button>

                    <Form form={form}>
                        <Form.Item>
                            <Input
                                value={billNo}
                                readOnly
                            />
                        </Form.Item>
                    </Form>

                    <Table
                        dataSource={filteredProduct}
                        columns={columns}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        pagination={false}
                        scroll={{ x: true, y: 320 }}
                        className="table-container"
                    />

                    <Form form={form}>
                        <Form.Item>
                            <Button onClick={handleSalesPage}>Bill</Button>
                        </Form.Item>
                    </Form>

                    <Modal
                        open={isModalVisible}
                        onCancel={() => {
                            setIsModalVisible(false)
                            setButtonClicked(true)
                        }}
                        footer={null}
                    >
                        <Search
                            placeholder="Search"
                            value={searchCategory}
                            onChange={(e) => handleSearchCategory(e.target.value)}
                            className="search-field"
                        />

                        <Table
                            dataSource={filteredCategory}
                            columns={columnsCategory}
                            pagination={false}
                            scroll={{ y: 320 }}

                            onRow={(record) => ({
                                onClick: () => onRowClickQty(record),
                            })}
                        />
                    </Modal>
                    <Modal
                        open={isModalVisibleQty}
                        onCancel={() => {
                            setIsModalVisibleQty(false)
                        }}
                        footer={null}
                    >
                        <Form form={form} onFinish={handleSubmit}>
                            <Form.Item
                                name='qty'
                                label={categoryName + " | " + categoryStyle}
                                rules={
                                    [
                                        { required: true, message: 'Enter Quantity.' },
                                    ]
                                }
                            >
                                <Input
                                    placeholder="Quantity"
                                />
                            </Form.Item>
                        </Form>

                    </Modal>
                </Col>
            </Row>
        </div>
    );
};

export default ViewSales;