import React, { useState } from "react";
import { Alert, Button, Col, Form, Input, Progress, Row } from "antd";
import { loginUser } from "../store/api/login";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginToken } from "../store/action/authAction";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../styles/default.scss';

const Login = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 

    const navigateTo = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        try {
            const { email, password } = values;
            if (!email || !password) {
                setError('Enter Valid Values');
                return;
            }

            //password length min 6
            if (password.length < 6) {
                setError('Enter 6 digit password');
                return;
            }

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const userData = { email, password };
            const { status, data } = await loginUser(userData);

            if (status === 200 && data.token) {
                dispatch(setLoginToken(data.token));
                setMessage(data.message);
                form.resetFields();
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);
                    setProgress(0);

                    navigateTo('/home'); // Navigate to the tournament page

                }, 2000);
            } else {
                setProgress(0);
                setError(data.message);
            }
        } catch (error) {
            setError('An error occurred during login.');
        }
    }

    return (
        <div className="user-form">
            <Row>
                <Col>
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            name="email"
                            rules={
                                [
                                    { required: true, message: "Please Enter Email" }
                                ]
                            }
                        >
                            <Input
                                type="email"
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={
                                [
                                    { required: true, message: "Please Enter Password" }
                                ]
                            }
                        >
                            <Input
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>

                        {/* Progress bar */}
                        <Progress percent={progress} />

                        {/* Success Message */}
                        {error && <Alert message={error} type="error" />}
                        {successMessage && (
                            <Alert
                                className='alert-message'
                                message={Message}
                                type="success"
                                showIcon
                                icon={<CheckCircleOutlined className='alert-icon' />}
                            />
                        )}

                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Login;