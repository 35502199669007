// component/home.jsx
import React from "react";
import SideBar from "../../components/common/sideBarNew.jsx";
import { Layout } from "antd";
import AppHeader from "../../components/common/Heder.jsx";
import ViewReturnCheque from "../../components/cheque/returnChequeView.jsx";

const { Content } = Layout;

const ReturnChequePage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <SideBar />
      <Layout>
        <AppHeader />
        <Content>
          <ViewReturnCheque />
        </Content>
      </Layout>
    </Layout>
  );
}

export default ReturnChequePage;
