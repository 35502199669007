import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, Table } from "antd";
import { getCheque, updateChequeAvailability } from "../../store/api/cheque";
import '../../styles/default.scss';
import Search from "antd/es/input/Search";

const { Option } = Select;

const ViewCheque = () => {
    const [form] = Form.useForm();
    const [cheque, setCheque] = useState([]);
    const [search, setSearch] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [SelectedCheque, setSelectedCheque] = useState(null);

    useEffect(() => {

        const fetchCheque = async () => {
            try {
                const response = await getCheque();
                if (response.status === 200) {
                    if (Array.isArray(response.data.data)) {
                        const chequeData = response.data.data.map((item) => ({
                            ...item,
                            key: item.ch_id,
                            date: formatDate(item.date),
                            ch_date: formatDate(item.ch_date),
                            time: formattedTime(item.time),
                            amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        }));
                        setCheque(chequeData);
                    } else {
                        console.error('API response data is not an array:', response.data);
                        setCheque([]);
                    }
                } else {
                    console.error('Failed to fetch cheque');
                }
            } catch (error) {
                console.error('Error fetching cheque:', error);
            }
        };

        fetchCheque();
    }, []);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formattedTime = (time) => {
        const parts = time.split(':');
        const hour = parseInt(parts[0], 10);
        const minute = parts[1];
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const displayHour = hour % 12 || 12; // Convert to 12-hour format

        return `${displayHour}:${minute} ${ampm}`;
    };

    const columns = [
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            key: 'bank',
        },
        {
            title: 'Cheque Date',
            dataIndex: 'ch_date',
            key: 'ch_date',
        },
        {
            title: 'cheque #',
            dataIndex: 'ch_number',
            key: 'ch_number',
        },
        {
            title: 'Status',
            dataIndex: 'ch_availability',
            key: 'ch_availability',
        },
    ];

    const handleSearchProductTransport = (value) => {
        setSearch(value);
    };

    const filteredProduct = cheque.filter(item =>
        item.customer.toLowerCase().includes(search.toLowerCase())
    );

    const onRowClick = (record) => {
        setSelectedCheque(record);
        setIsModalVisible(true);
        form.setFieldsValue(record);
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedCheque(null);
        setIsModalVisible(false);
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateChequeAvailability(SelectedCheque.ch_id, values);

            if (response.status === 200) {
                setCheque((prevCustomerAccount) =>
                    prevCustomerAccount.map((item) =>
                        item.ch_id === SelectedCheque.ch_id ? { ...item, ...values } : item
                    )
                );
                form.resetFields();
                setSelectedCheque(null);
                setIsModalVisible(false);
                window.location.reload();
            } else {
                console.error('Failed to update style category');
            }
        } catch (error) {
            console.error('Error updating style category:', error);
        }
    };

    return (
        <div className="user-form">

            <div className="search-container">
                <Search
                    placeholder="Search By Customer"
                    value={search}
                    onChange={(e) => handleSearchProductTransport(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredProduct}
                columns={columns}
                scroll={{ x: true, y: 380 }}
                pagination={false}
                onRow={(record) => ({
                    onClick: () => onRowClick(record),
                })}
            />

            <div className="modal-container">
                <Modal
                    title="Cheque Detail"
                    open={isModalVisible}
                    onOk={handleModalSubmit}
                    onCancel={handleModalCancel}
                    okText="Save"
                    cancelText="Cancel"
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="customer"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="date"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="time"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="amount"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="bank"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="ch_date"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="ch_number"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="ch_availability"
                        >
                            <Select placeholder="ch_availability">
                                <Option value="Return Cheque">Return Cheque</Option>
                                <Option value="Handover">Handover</Option>
                                <Option value="Payed">Payed</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default ViewCheque;