const initialState = {
  token: localStorage.getItem('token') || null, // Initialize token from local storage
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'SET_TOKEN':
          localStorage.setItem('token', action.payload); //token store in web localStore
          return { ...state, token: action.payload };
      case 'CLEAR_TOKEN':
          localStorage.removeItem('token'); //clear token in web localStore
          return { ...state, token: null };
      default:
          return state;
  }
};

export default authReducer;