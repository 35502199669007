import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";
import store from "../selectors/store";

export const getCustomerAccount = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.ALLCUSTOMER_ACCOUNT}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const getSingleCustomerAccount = async (customerId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.SINGLECUSTOMER_ACCOUNT}${customerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const getCustomerAccountLimit = async (customerId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CUSTOMER_ACCOUNTLIMIT}?id=${customerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const updateCustomerAccountLimit = async (customerId, updatedData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CUSTOMER_ACCOUNTLIMIT}/${customerId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
            body: JSON.stringify(updatedData),
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const updateCustomerAccountAmount = async (customerId, updatedData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CUSTOMER_ACCOUNTAMOUINT}/${customerId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
            body: JSON.stringify(updatedData),
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const deleteCustomerAccount = async (customerId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CUSTOMER_ACCOUNT}/${customerId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};