import React, { useEffect, useState } from "react";
import { Form, Modal, Table } from "antd";
import Search from "antd/es/input/Search";
import '../../styles/new.scss';
import { getCustomerAccount } from "../../store/api/customer_account";
import { getChequeById } from "../../store/api/cheque";
import moment from "moment";

const ViewCustomerAccountList = () => {
    const [form] = Form.useForm();
    const [customerAccount, setCustomerAccount] = useState([]);
    const [searchCustomerAccount, setSearchCustomerAccount] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [SelectedCustomerAccount, setSelectedCustomerAccount] = useState(null);
    const [customerCheque, setCustomerCheque] = useState([]);
    const [searchCustomerCheque, setSearchCustomerCheque] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');

    useEffect(() => {
        fetchCustomerAccount();

        const fetchCustomerCheque = async () => {
            if (SelectedCustomerAccount !== null) {
                try {
                    const response = await getChequeById(selectedCustomerId);
                    if (response.status === 200) {
                        if (Array.isArray(response.data.data)) {
                            const customerData = response.data.data.map((item) => ({
                                ...item,
                                key: item.ch_id,
                                ch_date: moment(item.ch_date).format("YYYY-MM-DD"),
                                amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                            }));
                            setCustomerCheque(customerData);
                        } else {
                            console.error('API response data is not an array:', response.data);
                            setCustomerCheque([]);
                        }
                    } else {
                        console.error('Failed to fetch customer account');
                    }
                } catch (error) {
                    console.error('Error fetching customer account:', error);
                }
            };

        };

        fetchCustomerCheque();
    }, [selectedCustomerId, SelectedCustomerAccount]);

    const fetchCustomerAccount = async () => {
        try {
            const response = await getCustomerAccount();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const customerData = response.data.data.map((item) => ({
                        ...item,
                        key: item.cus_id,
                        amount_limit: item.amount_limit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    }));
                    setCustomerAccount(customerData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setCustomerAccount([]);
                }
            } else {
                console.error('Failed to fetch customer account');
            }
        } catch (error) {
            console.error('Error fetching customer account:', error);
        }
    };

    const columns = [
        {
            title: 'Customer',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Amount Limit',
            dataIndex: 'amount_limit',
            key: 'amount_limit',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const chequeColumns = [
        {
            title: 'Cheque #',
            dataIndex: 'ch_number',
            key: 'ch_number',
        },
        {
            title: 'Cheque Date',
            dataIndex: 'ch_date',
            key: 'ch_date',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            key: 'bank',
        },
        {
            title: 'Status',
            dataIndex: 'ch_availability',
            key: 'ch_availability',
        },
    ];

    const handleSearchCustomerAccount = (value) => {
        setSearchCustomerAccount(value);
    };

    const filteredProduct = customerAccount.filter(item =>
        item.username.toLowerCase().includes(searchCustomerAccount.toLowerCase())
    );

    const onRowClick = (record) => {
        setSelectedCustomerAccount(record);
        setSelectedCustomerId(record.id);
        setIsModalVisible(true);
        form.setFieldsValue(record);
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedCustomerAccount(null);
        setIsModalVisible(false);
    };

    const handleSearchCustomerCheque = (value) => {
        setSearchCustomerCheque(value);
    };

    const filteredCheque = customerCheque.filter(item =>
        item.ch_number.toLowerCase().includes(searchCustomerCheque.toLowerCase())
    );

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Customer"
                    value={searchCustomerAccount}
                    onChange={(e) => handleSearchCustomerAccount(e.target.value)}
                    className="search-field"
                />
            </div>

            <div className="table-container">
                <Table
                    dataSource={filteredProduct}
                    columns={columns}
                    scroll={{ x: true, y: 380 }}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => onRowClick(record),
                    })}
                />
            </div>

            <div className="modal-container">
                <Modal
                    title="Customer Cheque List"
                    open={isModalVisible}
                    footer={null}
                    onCancel={handleModalCancel}
                >
                    <div className="search-container">
                        <Search
                            placeholder="Search By Cheque #"
                            value={searchCustomerCheque}
                            onChange={(e) => handleSearchCustomerCheque(e.target.value)}
                            className="search-field"
                        />
                    </div>

                    <Table
                        dataSource={filteredCheque}
                        columns={chequeColumns}
                        scroll={{ x: true, y: 380 }}
                        pagination={false}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default ViewCustomerAccountList;