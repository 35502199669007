// component/home.jsx
import React from "react";
import SideBar from "../../components/common/sideBarNew.jsx";
import { Layout } from "antd";
import CreateProduct from "../../components/product/create.jsx";
import AppHeader from "../../components/common/Heder.jsx";

const { Content } = Layout;

const CreateProductPage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <SideBar />
      <Layout>
        <AppHeader />
        <Content>
          <CreateProduct />
        </Content>
      </Layout>
    </Layout>
  );
}

export default CreateProductPage;
