import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";
import store from "../selectors/store";

export const createCheque = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CHEQUE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error;
    }
};

export const getCheque = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.ALLCHEQUE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const getReturnCheque = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.RETURNCHEQUE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const getChequeById = async (chequeId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CHEQUE}?id=${chequeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const updateChequeAvailability = async (chequeId, updatedData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CHEQUEAVAILABILITY}/${chequeId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
            body: JSON.stringify(updatedData),
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const deleteCheque = async (chequeId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.CHEQUE}/${chequeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};