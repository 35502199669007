import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearToken } from '../action/authAction';

const TokenDestroy = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let timeoutId;

        const resetTimer = () => {
            clearTimeout(timeoutId); // Clear the previous timer
            timeoutId = setTimeout(() => {
                dispatch(clearToken());
            }, 3 * 60 * 10 * 1000); // 30 minuets 
        };

        // Reset the timer on user activity
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);

        // Initial timer setup
        resetTimer();

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            clearTimeout(timeoutId); // Clear the timer on unmount
        };
    }, [dispatch]);

    return null;
};

export default TokenDestroy;
