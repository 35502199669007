// component/home.jsx
import React from "react";
import { Layout } from "antd";
import ViewProductReceived from "../../components/product_received/view.jsx";
import TransportSideBar from "../../components/common/transportSideBar.jsx";
import AppHeader from "../../components/common/Heder.jsx";

const { Content } = Layout;

const ProductReceivedPage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <TransportSideBar />
      <Layout>
        <AppHeader />
        <Content>
          <ViewProductReceived />
        </Content>
      </Layout>
    </Layout>
  );
}

export default ProductReceivedPage;
