import React, { useState } from "react";
import { Alert, Button, Form, Input, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import { useSelector } from "react-redux";
import { createProduct_Transport } from "../../store/api/product_transport";

const CreateProductTransport = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const handleSubmit = async (values) => {
        try {
            const { vehicle_no, driver, category, style, qty, amount } = values;
            if (!vehicle_no || !driver || !category || !style || !qty || !amount) {
                setError('Enter Valid Values');
                return;
            }

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const response = await createProduct_Transport({
                vehicle_no,
                driver,
                category,
                style,
                qty,
                amount
            }, token);

            if (response.status === 200) {
                setMessage(response.data.message);
                form.resetFields();
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);
                    setProgress(0);

                    navigateTo('/product_transport');

                }, 2000);
            } else {
                setProgress(0);
                setError(response.data.message);
            }

        } catch (error) {
            setError('An error occurred during style.');
        }
    };

    return (
        <div className="user-form">
            <Form form={form} onFinish={handleSubmit}>

                <Form.Item
                    name="vehicle_no"
                    rules={
                        [
                            { required: true, message: "Please Enter Vehicle No" }
                        ]
                    }
                >
                    <Input
                        placeholder="XXX-####"
                    />
                </Form.Item>

                <Form.Item
                    name="driver"
                    rules={
                        [
                            { required: true, message: "Please Enter Driver Name" }
                        ]
                    }
                >
                    <Input
                        placeholder="Driver Name"
                    />
                </Form.Item>

                <Form.Item
                    name="category"
                    rules={
                        [
                            { required: true, message: "Please Enter Category" }
                        ]
                    }
                >
                    <Input
                        placeholder="Category"
                    />
                </Form.Item>

                <Form.Item
                    name="style"
                    rules={
                        [
                            { required: true, message: "Please Enter Style" }
                        ]
                    }
                >
                    <Input
                        placeholder="Style"
                    />
                </Form.Item>

                <Form.Item
                    name="qty"
                    rules={
                        [
                            { required: true, message: "Please Enter Quantity" }
                        ]
                    }
                >
                    <Input
                        placeholder="Quantity"
                    />
                </Form.Item>

                <Form.Item
                    name="amount"
                    rules={
                        [
                            { required: true, message: "Please Enter Amount" }
                        ]
                    }
                >
                    <Input
                        placeholder="Amount"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

                {/* Progress bar */}
                <Progress percent={progress} />

                {/* Success Message */}
                {error && <Alert message={error} type="error" />}
                {successMessage && (
                    <Alert
                        className='alert-message'
                        message={Message}
                        type="success"
                        showIcon
                        icon={<CheckCircleOutlined className='alert-icon' />}
                    />
                )}



            </Form>
        </div>
    );
};

export default CreateProductTransport;