import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import { useSelector } from "react-redux";
import { createSupplierAccessories, getSupplierAccessoriesLastBillNo } from "../../store/api/supplier_accessories";

const Create = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 
    const [billNo, setBillNo] = useState('');

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        fetchSupplierAccessoriesLastBillNo();
    }, [])

    const fetchSupplierAccessoriesLastBillNo = async () => {
        try {
            const response = await getSupplierAccessoriesLastBillNo();
            if (response.status === 200) {
                const supplierData = response.data.data.bill_no;
                setBillNo(supplierData);
            } else {
                console.error('Failed to fetch supplier');
            }
        } catch (error) {
            console.error('Error fetching supplier:', error);
        }
    };

    const handleSubmit = async (values) => {
        try {
            const { u_price, qty, description, bill_no } = values;
            if (!u_price || !qty || !description || !bill_no) {
                setError('Enter Valid Values');
                return;
            }

            const amount = qty * u_price;

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const response = await createSupplierAccessories({
                bill_no,
                description,
                qty,
                u_price,
                amount,
            }, token);

            if (response.status === 200) {
                setMessage(response.data.message);
                form.resetFields(['description', 'qty', 'u_price']);
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);
                    setProgress(0);

                    navigateTo('/supplier_accessories');

                }, 2000);
            } else {
                setProgress(0);
                setError(response.data.message);
            }

        } catch (error) {
            setError('An error occurred during supplier accessories.');
        }
    };

    const handleList = () => {
        navigateTo('/supplier_accessories_last_bill')
    }

    return (
        <div className="user-form">
            <Form form={form} onFinish={handleSubmit} >
                <Form.Item
                    name="bill_no"
                    rules={
                        [
                            { required: true, message: "Please Enter Bill No" }
                        ]
                    }
                >
                    <Input 
                        placeholder={billNo}
                    />
                </Form.Item>

                <Form.Item
                    name="description"
                    rules={
                        [
                            { required: true, message: "Please Enter Description" }
                        ]
                    }
                >
                    <Input
                        placeholder="Description"
                    />
                </Form.Item>

                <Form.Item
                    name="qty"
                    rules={
                        [
                            { required: true, message: "Please Enter Qty" }
                        ]
                    }
                >
                    <Input
                        placeholder="Quantity"
                    />
                </Form.Item>

                <Form.Item
                    name="u_price"
                    rules={
                        [
                            { required: true, message: "Please Enter Unit Price" }
                        ]
                    }
                >
                    <Input
                        placeholder="Unit Price"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

                {/* Progress bar */}
                <Progress percent={progress} />

                {/* Success Message */}
                {error && <Alert message={error} type="error" />}
                {successMessage && (
                    <Alert
                        className='alert-message'
                        message={Message}
                        type="success"
                        showIcon
                        icon={<CheckCircleOutlined className='alert-icon' />}
                    />
                )}

                <Form.Item>
                    <Button onClick={handleList}>View Last Bill</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Create;