// component/home.jsx
import React from "react";
import SideBar from "../../components/common/sideBarNew.jsx";
import { Layout } from "antd";
import Bill from "../../components/sales/bill.jsx";
import AppHeader from "../../components/common/Heder.jsx";

const { Content } = Layout;

const BillPage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <SideBar />
      <Layout>
        <AppHeader />
        <Content>
          <Bill />
        </Content>
      </Layout>
    </Layout>
  );
}

export default BillPage;
