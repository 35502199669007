import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Table } from "antd";
import moment from "moment";
import { getProduct_Transport, updateProduct_Transport } from "../../store/api/product_transport";
import { EyeOutlined } from '@ant-design/icons';
import '../../styles/new.scss';
import Search from "antd/es/input/Search";

const ViewProductTransport = () => {
    const [product, setProduct] = useState([]);

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSelectProductTransport, setSelectProductTransport] = useState(null);
    const [searchProduct, setSearchProduct] = useState('');

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        try {
            const response = await getProduct_Transport();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const productData = response.data.data.map((item) => ({
                        ...item,
                        key: item.pt_id,
                        date: moment(item.date).format("YYYY-MM-DD"),
                        issue_date: moment(item.issue_date).format("YYYY-MM-DD"),
                    }));
                    setProduct(productData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setProduct([]);
                }
            } else {
                console.error('Failed to fetch product');
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectProductTransport(null);
        setIsModalVisible(false);
    };

    const handleData = (product) => {
        setSelectProductTransport(product);
        setIsModalVisible(true);
        form.setFieldsValue(product);
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            values.pt_status = "Delivered"
            const response = await updateProduct_Transport(isSelectProductTransport.pt_id, values);

            if (response.status === 200) {
                setProduct((prevStyle) =>
                    prevStyle.map((item) =>
                        item.pt_id === isSelectProductTransport.pt_id ? { ...item, ...values } : item
                    )
                );

                form.resetFields();
                setSelectProductTransport(null);
                setIsModalVisible(false);
            } else {
                console.error('Failed to update style');
            }
        } catch (error) {
            console.error('Error updating style:', error);
        }
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Vehicle #',
            dataIndex: 'vehicle_no',
            key: 'vehicle_no',
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Style',
            dataIndex: 'style',
            key: 'style',
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Delivery Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
        },
        {
            title: 'Delivery Time',
            dataIndex: 'issue_time',
            key: 'issue_time',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'pt_status',
            dataIndex: 'pt_status',
            key: 'pt_status',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, product) => (
                <div>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EyeOutlined />}
                        onClick={() => handleData(product)}
                    />
                </div>
            ),
        },
    ];

    const handleSearchProductTransport = (value) => {
        setSearchProduct(value);
    };

    const filteredProduct = product.filter(item =>
        item.style.toLowerCase().includes(searchProduct.toLowerCase())
    );

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Style"
                    value={searchProduct}
                    onChange={(e) => handleSearchProductTransport(e.target.value)}
                    className="search-field"
                />
            </div>

            <div className="table-container">
                <Table
                    dataSource={filteredProduct}
                    columns={columns}
                    scroll={{ x: true, y: 320 }}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => handleData(record),
                    })}

                />
            </div>

            <div className="modal-container">
                <Modal
                    title="Product Transport Details"
                    open={isModalVisible}
                    footer={null}
                    onCancel={handleModalCancel}
                >
                    <Form form={form} layout="vertical">

                        {/* <Form.Item
                        name="pt_id"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>
                    */}

                        <Form.Item
                            name="date"
                            label="Picked Date"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="time"
                            label="Picked Time"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="vehicle_no"
                            label="Vehicle #"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="driver"
                            label="Driver"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="category"
                            label="Category"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="style"
                            label="Style"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="qty"
                            label="Qty"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="issue_date"
                            label="Issue Date"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="issue_time"
                            label="Issue Time"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="amount"
                            label="Amount"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            name="pt_status"
                            label="Status"
                        >
                            <Input
                                disabled
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={handleModalSubmit}>Delivered</Button>
                        </Form.Item>

                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default ViewProductTransport;