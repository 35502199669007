// component/home.jsx
import React from "react";
import { Layout } from "antd";
import TransportSideBar from "../../components/common/transportSideBar.jsx";
import AppHeader from "../../components/common/Heder.jsx";
import ViewProductReceivedAdminApprove from "../../components/product_received/viewPendingApprove.jsx";

const { Content } = Layout;

const ProductReceivedAdminApprovePage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <TransportSideBar />
      <Layout>
        <AppHeader />
        <Content>
          <ViewProductReceivedAdminApprove />
        </Content>
      </Layout>
    </Layout>
  );
}

export default ProductReceivedAdminApprovePage;
