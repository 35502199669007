import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Table } from "antd";
import { deleteCategory, getCategory, updateCategory } from "../../store/api/category";
import { DeleteOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import Search from "antd/es/input/Search";

const ViewCategory = () => {
    const [form] = Form.useForm();
    const [category, setCategory] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [search, setSearch] = useState('');

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchCategory();
    }, []);

    const fetchCategory = async () => {
        try {
            const response = await getCategory();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const categoryData = response.data.data.map((item) => ({
                        ...item,
                        key: item.c_id,
                    }));
                    setCategory(categoryData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setCategory([]);
                }
            } else {
                console.error('Failed to fetch category');
            }
        } catch (error) {
            console.error('Error fetching category:', error);
        }
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedCategory(null);
        setIsModalVisible(false);
    };

    /*
        const handleEdit = (category) => {
            setSelectedCategory(category);
            setIsModalVisible(true);
            form.setFieldsValue(category);
        };
    */

    const handleDelete = async (category) => {
        try {
            const response = await deleteCategory(category.c_id);

            if (response.status === 200) {
                setCategory((prevCategory) =>
                    prevCategory.filter((item) => item.c_id !== category.c_id)
                );
            } else {
                console.error('Failed to delete category');
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateCategory(selectedCategory.c_id, values);

            if (response.status === 200) {
                setCategory((prevCategory) =>
                    prevCategory.map((item) =>
                        item.c_id === selectedCategory.c_id ? { ...item, ...values } : item
                    )
                );

                form.resetFields();
                setSelectedCategory(null);
                setIsModalVisible(false);
            } else {
                console.error('Failed to update category');
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, category) => (
                <div>
                    {/*
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(category)}
                    />
                    */}
                    <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(category)}
                    />
                </div>
            ),
        },
    ];

    const onRowClick = (record) => {
        //const categoryId = record.c_id;
        // navigateTo(`/tournament?categoryId=${categoryId}`);
    };

    const handleSearch = (value) => {
        setSearch(value);
    };

    const filteredSearch = category.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">
             <div className="search-container">
                <Search
                    placeholder="Search By Category"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredSearch}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ pageSize: 5 }}
               onRow={(record) => ({
                    onClick: () => onRowClick(record),
                })}
            />

            <Modal
                title="category Details"
                open={isModalVisible}
                onOk={handleModalSubmit}
                onCancel={handleModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">

                    <Form.Item
                        name="c_id"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="Category Name"
                        rules={[
                            { required: true, message: 'Please Enter Name.' },
                        ]}
                    >
                        <Input
                            placeholder="Category Name"
                        />
                    </Form.Item>

                    <Form.Item
                        name="qty"
                        label="Quantity"
                        rules={[
                            { required: true, message: 'Please Enter Quantity.' },
                        ]}
                    >
                        <Input
                            placeholder="Quantity"
                        />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default ViewCategory;