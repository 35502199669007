export const API_ENDPOINT = {
    USER: '/api/users/login',
    REGISTER: '/api/users/register',
    STYLE: '/api/style/style',
    AllSTYLE: '/api/style/styleAll',
    CATEGORY: '/api/category/category',
    AllCATEGORY: '/api/category/categoryList',
    STYLE_CATEGORY: '/api/style_category/style_category',
    ALLSTYLE_CATEGORY: '/api/style_category/style_categoryAll',
    PRODUCT: '/api/product/product',
    ALLPRODUCT: '/api/product/productList',

    CHEQUE: '/api/cheque/cheque',
    ALLCHEQUE: '/api/cheque/chequeList',
    CHEQUEAVAILABILITY: '/api/cheque/cheque_available',
    RETURNCHEQUE: '/api/cheque/chequeReturn',

    STYLE_CATEGORY_CATE: '/api/style_category/style_category_cate',
    BILL_NO: '/api/bill/billNo',
    BILL: '/api/bill/bill',

    CATEGORY_QTY: '/api/category/categoryQty',
    STYLE_CATEGORY_QTY: '/api/style_category/style_categoryQty',

    SALES: '/api/sales/sales',
    ALLSALES: '/api/sales/salesList',
    BillSALES: '/api/sales/salesBill',

    CUSTOMER_USER: '/api/users/user_customer',
    CUSTOMER: '/api/users/customer',

    PRODUCT_TRANSPORT: '/api/product_transport/product_transport',
    ALLPRODUCT_TRANSPORT: '/api/product_transport/product_transportList',

    PRODUCT_RECEIVED: '/api/product_received/product_received',
    ALLPRODUCT_RECEIVED: '/api/product_received/product_receivedList',
    PRODUCT_RECEIVED_PENDING: '/api/product_received/product_received_pending',

    USERNAME: '/api/users/user_name?email=',

    CUSTOMER_ACCOUNT: '/api/customer_account/customer_account',
    ALLCUSTOMER_ACCOUNT: '/api/customer_account/customer_accountList',
    SINGLECUSTOMER_ACCOUNT: '/api/customer_account/customer_account_single?id=',
    CUSTOMER_ACCOUNTLIMIT: '/api/customer_account/customer_account_limit',
    CUSTOMER_ACCOUNTAMOUINT: '/api/customer_account/customer_account_amount',

    SUPPLIER: '/api/supplier/supplier',
    ALLSUPPLIER: '/api/supplier/supplierList',
    SUPPLIERSTATUS: '/api/supplier/supplier_status',
    
    SUPPLIER_ACCESSORIES: '/api/supplier_accessories/supplier_accessories',
    ALLSUPPLIER_ACCESSORIES: '/api/supplier_accessories/supplier_accessoriesList',
    SUPPLIER_ACCESSORIES_LastBILL_NO: '/api/supplier_accessories/supplier_accessories_billNo',
    SUPPLIER_ACCESSORIES_PAYMENT: '/api/supplier_accessories_payment/supplier_accessories_payment',
    ALLSUPPLIER_ACCESSORIES_PAYMENT: '/api/supplier_accessories_payment/supplier_accessories_paymentList',

    SUPPLIER_FABRIC: '/api/supplier_fabric/supplier_fabric',
    ALLSUPPLIER_FABRIC: '/api/supplier_fabric/supplier_fabricList',
    SUPPLIER_FABRIC_LastBILL_NO: '/api/supplier_fabric/supplier_fabric_billNo',
    SUPPLIER_FABRIC_PAYMENT: '/api/supplier_fabric_payment/supplier_fabric_payment',
    ALLSUPPLIER_FABRIC_PAYMENT: '/api/supplier_fabric_payment/supplier_fabric_paymentList',
};