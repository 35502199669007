import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getReturnCheque } from "../../store/api/cheque";
import '../../styles/default.scss';
import Search from "antd/es/input/Search";

const ViewReturnCheque = () => {
    const [cheque, setCheque] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {

        const fetchCheque = async () => {
            try {
                const response = await getReturnCheque();
                if (response.status === 200) {
                    if (Array.isArray(response.data.data)) {
                        const chequeData = response.data.data.map((item) => ({
                            ...item,
                            key: item.ch_id,
                            date: formatDate(item.date),
                            ch_date: formatDate(item.ch_date),
                            time: formattedTime(item.time),
                            amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        }));
                        setCheque(chequeData);
                    } else {
                        console.error('API response data is not an array:', response.data);
                        setCheque([]);
                    }
                } else {
                    console.error('Failed to fetch cheque');
                }
            } catch (error) {
                console.error('Error fetching cheque:', error);
            }
        };

        fetchCheque();
    }, []);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formattedTime = (time) => {
        const parts = time.split(':');
        const hour = parseInt(parts[0], 10);
        const minute = parts[1];
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const displayHour = hour % 12 || 12; // Convert to 12-hour format

        return `${displayHour}:${minute} ${ampm}`;
    };

    const columns = [
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            key: 'bank',
        },
        {
            title: 'Cheque Date',
            dataIndex: 'ch_date',
            key: 'ch_date',
        },
        {
            title: 'cheque #',
            dataIndex: 'ch_number',
            key: 'ch_number',
        },
        {
            title: 'Status',
            dataIndex: 'ch_availability',
            key: 'ch_availability',
        },
    ];

    const handleSearchProductTransport = (value) => {
        setSearch(value);
    };

    const filteredProduct = cheque.filter(item =>
        item.customer.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">

            <div className="search-container">
                <Search
                    placeholder="Search By Customer"
                    value={search}
                    onChange={(e) => handleSearchProductTransport(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredProduct}
                columns={columns}
                scroll={{ x: true, y: 380 }}
                pagination={false}
            />

        </div>
    );
};

export default ViewReturnCheque;