import React, { useState } from "react";
import { Alert, Button, Col, Form, Input, Progress, Row } from "antd";
import { registerUser } from "../store/api/login";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../styles/default.scss';

const Register = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 

    const navigateTo = useNavigate();

    const handleSubmit = async (values) => {
        try {
            const { username, email, password, confirm_password, phone_number, address } = values;
            if (!username || !email || !password || !confirm_password || !phone_number || !address) {
                setError('Enter Valid Values');
                return;
            }

            //password length min 6
            if (password.length < 6) {
                setError('Enter 6 digit password');
                return;
            } else {
                if (password !== confirm_password) {
                    setError('Password not matched');
                    return;
                }
            }

            if (!phone_number.match(/^-?\d*\.?\d*$/)) {
                setError('Phone number should be number');
                return;
            } else {
                if (phone_number.length !== 10) {
                    setError('Phone number should be 10 digit');
                    return;
                }
            }

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const response = await registerUser({
                username,
                role: 'user',
                email,
                password,
                phone_number,
                address,
                acc_status: false
            })

            if (response.status === 200) {
                setMessage(response.data.message);
                form.resetFields();
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);
                    setProgress(0);

                    navigateTo('/login'); // Navigate to the tournament page

                }, 2000);
            } else {
                setProgress(0);
                setError(response.data.message);
            }
        } catch (error) {
            setError('An error occurred during registration.');
        }
    }

    return (
        <div className="user-form">
            <Row>
                <Col>
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            name="username"
                            rules={
                                [
                                    { required: true, message: "Please Enter Name" }
                                ]
                            }
                        >
                            <Input
                                placeholder="Name"
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={
                                [
                                    { required: true, message: "Please Enter Email" }
                                ]
                            }
                        >
                            <Input
                                type="email"
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={
                                [
                                    { required: true, message: "Please Enter Password" }
                                ]
                            }
                        >
                            <Input
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item
                            name="confirm_password"
                            rules={
                                [
                                    { required: true, message: "Please Enter Password" }
                                ]
                            }
                        >
                            <Input
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item
                            name="phone_number"
                            rules={
                                [
                                    { required: true, message: "Please Enter Phone Number" }
                                ]
                            }
                        >
                            <Input
                                placeholder="Phone Number"
                            />
                        </Form.Item>

                        <Form.Item
                            name="address"
                            rules={
                                [
                                    { required: true, message: "Please Enter Address" }
                                ]
                            }
                        >
                            <Input
                                placeholder="Address"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">Register</Button>
                        </Form.Item>

                        {/* Progress bar */}
                        <Progress percent={progress} />

                        {/* Success Message */}
                        {error && <Alert message={error} type="error" />}
                        {successMessage && (
                            <Alert
                                className='alert-message'
                                message={Message}
                                type="success"
                                showIcon
                                icon={<CheckCircleOutlined className='alert-icon' />}
                            />
                        )}
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Register;