import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";
import store from "../selectors/store";

export const getBillNo = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.BILL_NO}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const getBill = async (billNo) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.BillSALES}?bill_no=${billNo}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const createBill = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.BILL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error;
    }
};
