import React, { useEffect, useState } from "react";
import { Table } from "antd";
import '../../styles/default.scss';
import Search from "antd/es/input/Search";
import { getSupplier } from "../../store/api/supplier";

const View = () => {
    //const [form] = Form.useForm();
    const [supplier, setSupplier] = useState([]);
    //const [isModalVisible, setIsModalVisible] = useState(false);
    //const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [search, setSearch] = useState('');

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchSupplier();
    }, []);

    const fetchSupplier = async () => {
        try {
            const response = await getSupplier();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const supplierData = response.data.data.map((item) => ({
                        ...item,
                        key: item.sup_id,
                        amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    }));
                    setSupplier(supplierData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setSupplier([]);
                }
            } else {
                console.error('Failed to fetch supplier');
            }
        } catch (error) {
            console.error('Error fetching supplier:', error);
        }
    };

    /*
        const handleModalCancel = () => {
            form.resetFields();
            setSelectedSupplier(null);
            setIsModalVisible(false);
        };
    
        const handleEdit = (supplier) => {
            setSelectedSupplier(supplier);
            setIsModalVisible(true);
            form.setFieldsValue(supplier);
        };
    
    
        const handleDelete = async (supplier) => {
            try {
                const response = await deleteStyle(supplier.sup_id);
    
                if (response.status === 200) {
                    setStyle((prevStyle) =>
                        prevStyle.filter((item) => item.s_id !== style.s_id)
                    );
                } else {
                    console.error('Failed to delete style');
                }
            } catch (error) {
                console.error('Error deleting style:', error);
            }
        };
    
        const handleModalSubmit = async () => {
            try {
                const values = await form.validateFields();
                const response = await updateS(selectedSupplier.sup_id, values);
    
                if (response.status === 200) {
                    setStyle((prevStyle) =>
                        prevStyle.map((item) =>
                            item.s_id === selectedStyle.s_id ? { ...item, ...values } : item
                        )
                    );
    
                    form.resetFields();
                    setSelectedStyle(null);
                    setIsModalVisible(false);
                } else {
                    console.error('Failed to update style');
                }
            } catch (error) {
                console.error('Error updating style:', error);
            }
        };
    */

    const columns = [
        {
            title: 'Supplier',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Shop',
            dataIndex: 'shop',
            key: 'shop',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    /*
    const onRowClick = (record) => {
        //const styleId = record.s_id;
        // navigateTo(`/tournament?styleId=${styleId}`);
    };
    */

    const handleSearch = (value) => {
        setSearch(value);
    };

    const filteredSearch = supplier.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Supplier"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredSearch}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ pageSize: 6 }}
            /*onRow={(record) => ({
                onClick: () => onRowClick(record),
            })}*/
            />

            {/* 
            <Modal
                title="Style Details"
                open={isModalVisible}
                onOk={handleModalSubmit}
                onCancel={handleModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">

                    <Form.Item
                        name="s_id"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="Style Name"
                        rules={[
                            { required: true, message: 'Please Enter Name.' },
                        ]}
                    >
                        <Input
                            placeholder="Style Name"
                        />
                    </Form.Item>

                </Form>
            </Modal>
            */}
        </div>
    );
};

export default View;