import React from 'react';
import { Table } from 'antd';

const dataSource = [
  {
    key: '1',
    product: 'Product A',
    qty: 5,
    price: 10,
  },
  {
    key: '2',
    product: 'Product B',
    qty: 3,
    price: 15,
  },
  {
    key: '3',
    product: 'Product C',
    qty: 2,
    price: 20,
  },
];

const columns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Total',
    key: 'total',
    render: (text, record) => record.qty * record.price,
  },
];

const ProductTable = () => {
  // Calculate the final total
  const finalTotal = dataSource.reduce((total, record) => {
    return total + (record.qty * record.price);
  }, 0);

  // Log the final total to the console
  console.log('Final Total:', finalTotal);

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default ProductTable;
