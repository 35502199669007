import React, { useState } from "react";
import { Alert, Button, Form, Input, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import { useSelector } from "react-redux";
import { createCustomer } from "../../store/api/user";

const CreateCustomer = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const handleSubmit = async (values) => {
        try {
            const { address, phone, nic, name, email, limit } = values;
            if (!name || !address || !phone || !nic || !email || !limit) {
                setError('Enter Valid Values');
                return;
            }

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const response = await createCustomer({
                username: name,
                email,
                password: nic,
                phone_number: phone,
                address,
                acc_status: false,
                amount_limit: limit,
            }, token);

            if (response.status === 200) {
                    setMessage(response.data.message);
                    form.resetFields();
                    setError('');
                    setSuccessMessage(true);

                    // Hide the success message after 2 seconds
                    setTimeout(() => {
                        setSuccessMessage(false);
                        setProgress(0);

                        navigateTo('/allcustomer');

                    }, 2000);               
            } else {
                setProgress(0);
                setError(response.data.message);
            }

        } catch (error) {
            setError('An error occurred during customer.');
        }
    };

    return (
        <div className="user-form">
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                    name="name"
                    rules={
                        [
                            { required: true, message: "Please Enter Name" }
                        ]
                    }
                >
                    <Input
                        placeholder="Name"
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={
                        [
                            { required: true, message: "Please Enter Email" }
                        ]
                    }
                >
                    <Input
                        type="email"
                        placeholder="Email"
                    />
                </Form.Item>

                <Form.Item
                    name="nic"
                    rules={
                        [
                            { required: true, message: "Please Enter NIC" }
                        ]
                    }
                >
                    <Input
                        placeholder="NIC"
                    />
                </Form.Item>

                <Form.Item
                    name="phone"
                    rules={
                        [
                            { required: true, message: "Please Enter Phone Number" }
                        ]
                    }
                >
                    <Input
                        placeholder="Phone Number"
                    />
                </Form.Item>

                <Form.Item
                    name="address"
                    rules={
                        [
                            { required: true, message: "Please Enter Address" }
                        ]
                    }
                >
                    <Input
                        placeholder="Address"
                    />
                </Form.Item>

                <Form.Item
                    name="limit"
                    rules={
                        [
                            { required: true, message: "Please Enter Amount Limit" }
                        ]
                    }
                >
                    <Input
                        placeholder="Limit"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

                {/* Progress bar */}
                <Progress percent={progress} />

                {/* Success Message */}
                {error && <Alert message={error} type="error" />}
                {successMessage && (
                    <Alert
                        className='alert-message'
                        message={Message}
                        type="success"
                        showIcon
                        icon={<CheckCircleOutlined className='alert-icon' />}
                    />
                )}

            </Form>
        </div>
    );
};

export default CreateCustomer;