// component/home.jsx
import React from "react";
import SideBar from "../../components/common/supplierSideBar.jsx";
import { Layout } from "antd";
import View from "../../components/supplierAccessories/view.jsx";
import AppHeader from "../../components/common/Heder.jsx";

const { Content } = Layout;

const SupplierPaymentListPage = () => {
  return (

    <Layout style={{ minHeight: '100vh' }}>
      <SideBar />
      <Layout>
        <AppHeader />
        <Content>
          <View />
        </Content>
      </Layout>
    </Layout>
  );
}

export default SupplierPaymentListPage;
