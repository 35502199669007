import { Button, Form, Input, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { createBill, getBill } from "../../store/api/bill";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { getCustomerList } from "../../store/api/user";
import { useSelector } from "react-redux";
//import { createCheque } from "../../store/api/cheque";

const { Option } = Select;

const Bill = () => {
    const [form] = Form.useForm();

    const [sales, setSales] = useState('');
    const [isModalVisibleCustomer, setIsModalVisibleCustomer] = useState(false);
    // const [isModalVisibleCheque, setIsModalVisibleCheque] = useState(false);
    //const [isModalVisibleCard, setIsModalVisibleCard] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [getCustomer, setGetCustomer] = useState([]);
    const [customerButtonClicked, setCustomerButtonClicked] = useState(false);
    const [customer, setCustomer] = useState('');
    const [customerId, setCustomerId] = useState('');

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const BillNo = searchParams.get('bill_no');

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        fetchSales(BillNo);
    }, [BillNo]);

    const fetchSales = async (BillNo) => {
        try {
            const response = await getBill(BillNo);
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const salesDate = response.data.data.map((item) => ({
                        ...item,
                        key: item.sl_id,
                    }));
                    setSales(salesDate);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setSales([]);
                }
            } else {
                console.error('Failed to fetch sales');
            }
        } catch (error) {
            console.error('Error fetching sales:', error);
        }
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Style',
            dataIndex: 'style',
            key: 'style',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const handleTotal = () => {
        let total = 0;
        for (const sale of sales) {
            total = total + sale.total;
        }
        const formattedTotal = total.toFixed(2);
        return formattedTotal;
    }

    useEffect(() => {
        if (customerButtonClicked === true) {
            fetchCustomer();
            setCustomerButtonClicked(false);
        }
    }, [customerButtonClicked]);

    const fetchCustomer = async () => {
        try {
            const response = await getCustomerList();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const customerData = response.data.data.map((item) => ({
                        ...item,
                        key: item.id,
                    }));
                    setGetCustomer(customerData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setGetCustomer([]);
                }
            } else {
                console.error('Failed to fetch user');
            }
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    const customerColumn = [
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
        },
    ];

    const handleCustomer = () => {
        setIsModalVisibleCustomer(true);
        setCustomerButtonClicked(true);
    }

    const handleSearchCustomer = (value) => {
        setSearchCustomer(value);
    };

    const filteredCustomer = getCustomer.filter(item =>
        item.username.toLowerCase().includes(searchCustomer.toLowerCase())
    );

    const onRowClick = (record) => {
        setCustomerId(record.id);
        setCustomer(record.username);
        setIsModalVisibleCustomer(false);
    }

    const handleSubmitBill = async (values) => {
        try {
            const totalAmount = handleTotal();
            const { payment, amount } = values;
            if (payment === "cash") {
                handleBilled(amount, payment, totalAmount);
            } if (payment === "cheque") {
                //  setIsModalVisibleCheque(true);
            } else if (payment === "card") {
                //setIsModalVisibleCard(true);
            }
        } catch (error) {
            console.log("Error ", error);
        }
    };

    const handleBilled = async (amount, payment, totalAmount) => {

        if (customerId !== '') {
            const currentDateAndTime = new Date();
            const date = currentDateAndTime.toISOString().split('T')[0];
            const time = currentDateAndTime.toTimeString().split(' ')[0];

            const response = await createBill({
                date,
                time,
                cashier: 'Cashier',
                customer: customer,
                amount: totalAmount,
                discount: 0,
                payment,
                total: amount,
            }, token);

            if (response.status === 200) {
                navigateTo('/sales');
            } else {
                console.log(response.data);
            }

        } else {
            alert('Select Customer');
        }
    }

    /*
    const handleCheque = async (values) => {
        try {
            const { cheque_number, cheque_date, ch_amount, bank } = values;
            console.log(values);
            const response = await createCheque({
                id: customerId,
                bill_no: BillNo,
                amount: ch_amount,
                bank: bank,
                ch_date: cheque_date,
                ch_number: cheque_number,
            }, token);

            if (response.status === 200) {
                isModalVisibleCheque(false);
            } else {
                console.log(response.data);
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }
*/
    return (
        <div className="user-form">
            <Form form={form} onFinish={handleSubmitBill}>
                <Button onClick={handleCustomer}>Customer</Button>
                <Form.Item
                    rules={
                        [
                            { required: true, message: "Please Select Customer" }
                        ]
                    }
                >
                    <Input
                        value={customer}
                        readOnly
                    />
                </Form.Item>
                <Table
                    columns={columns}
                    dataSource={sales}
                    pagination={false}
                    scroll={{ y: 320 }}
                />

                <Form.Item
                    label='Total'
                >
                    <Input
                        value={handleTotal()}
                        readOnly
                    />
                </Form.Item>

                <Form.Item
                    label='Payment'
                    name='payment'
                    initialValue="cash"
                >
                    <Select placeholder="Select Payment Method">
                        <Option value="cash">Cash</Option>
                        <Option value="cheque">Cheque</Option>
                        <Option value="card">Card</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label='Amount'
                    name='amount'
                    rules={
                        [
                            { required: true, message: "Please Amount" }
                        ]
                    }
                >
                    <Input
                        placeholder="Amount"
                    />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Print
                </Button>

            </Form>

            <Modal
                open={isModalVisibleCustomer}
                onCancel={() => {
                    setIsModalVisibleCustomer(false)
                }}
                footer={null}
            >
                <Search
                    placeholder="Search"
                    value={searchCustomer}
                    onChange={(e) => handleSearchCustomer(e.target.value)}
                    className="search-field"
                />

                <Table
                    columns={customerColumn}
                    dataSource={filteredCustomer}
                    pagination={false}
                    scroll={{ y: 320 }}
                    onRow={(record) => ({
                        onClick: () => onRowClick(record),
                    })}
                />
            </Modal>
            {/** 
                    <Modal
                        open={isModalVisibleCheque}
                        onCancel={() => {
                            setIsModalVisibleCheque(false)
                            handleBilled()
                        }}
                        onOk={form.submit}
                        okText="Save"
                    >

                        <Form form={form} onFinish={handleCheque}>
                            <Form.Item
                                label='Bill No'
                            >
                                <Input
                                    value={BillNo}
                                    readOnly
                                />
                            </Form.Item>

                            <Form.Item
                                label={'Customer: ' + customer}
                            >
                                <Input
                                    value={customerId}
                                    readOnly
                                />
                            </Form.Item>

                            <Form.Item
                                name='ch_amount'
                                label='Amount'
                                rules={
                                    [
                                        { required: true, message: "Please Enter Cheque Amount" }
                                    ]
                                }
                            >
                                <Input
                                    placeholder="Enter Cheque Amount"
                                />
                            </Form.Item>

                            <Form.Item
                                name='bank'
                                label='Bank'
                                rules={
                                    [
                                        { required: true, message: "Please Enter Bank" }
                                    ]
                                }
                            >
                                <Input
                                    placeholder="Enter Bank"
                                />
                            </Form.Item>

                            <Form.Item
                                name="cheque_date"
                                rules={[
                                    { required: true, message: 'Please Select a Cheque Date.' },
                                ]}
                            >
                                <DatePicker className='date-picker'
                                    placeholder='Select Cheque Date' />
                            </Form.Item>

                            <Form.Item
                                name='cheque_number'
                                label='Cheque #'
                                rules={
                                    [
                                        { required: true, message: "Please Enter Cheque Number" }
                                    ]
                                }
                            >
                                <Input
                                    placeholder="Enter Cheque Number"
                                />
                            </Form.Item>

                        </Form>
                    </Modal>
                    */}
        </div>
    );
};

export default Bill;