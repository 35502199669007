import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Table, Form } from "antd";
import Search from "antd/es/input/Search";
import { EditOutlined } from '@ant-design/icons';
import '../../styles/new.scss';
import { getCustomerAccount, updateCustomerAccountLimit } from "../../store/api/customer_account";

const ViewCustomerAccountLimitList = () => {
    const [form] = Form.useForm();
    const [customerAccount, setCustomerAccount] = useState([]);
    const [searchCustomerAccount, setSearchCustomerAccount] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [SelectedCustomerAccount, setSelectedCustomerAccount] = useState(null);

    useEffect(() => {
        fetchCustomerAccount();
    }, []);

    const fetchCustomerAccount = async () => {
        try {
            const response = await getCustomerAccount();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const customerData = response.data.data.map((item) => ({
                        ...item,
                        key: item.cus_id,
                        amount_limit: item.amount_limit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    }));
                    setCustomerAccount(customerData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setCustomerAccount([]);
                }
            } else {
                console.error('Failed to fetch customer account');
            }
        } catch (error) {
            console.error('Error fetching customer account:', error);
        }
    };

    const columns = [
        {
            title: 'Customer',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Amount Limit',
            dataIndex: 'amount_limit',
            key: 'amount_limit',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, customerAccount) => (
                <div>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onRowClick(customerAccount)}
                    />
                </div>
            ),
        }
    ];

    const handleSearchCustomerAccount = (value) => {
        setSearchCustomerAccount(value);
    };

    const filteredProduct = customerAccount.filter(item =>
        item.username.toLowerCase().includes(searchCustomerAccount.toLowerCase())
    );

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedCustomerAccount(null);
        setIsModalVisible(false);
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateCustomerAccountLimit(SelectedCustomerAccount.id, values);

            if (response.status === 200) {
                setCustomerAccount((prevCustomerAccount) =>
                    prevCustomerAccount.map((item) =>
                        item.id === SelectedCustomerAccount.id ? { ...item, ...values } : item
                    )
                );
                form.resetFields();
                setSelectedCustomerAccount(null);
                setIsModalVisible(false);
                window.location.reload();
            } else {
                console.error('Failed to update style category');
            }
        } catch (error) {
            console.error('Error updating style category:', error);
        }
    };

    const onRowClick = (record) => {
        setSelectedCustomerAccount(record);
        setIsModalVisible(true);
        form.setFieldsValue(record);
    };

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Customer"
                    value={searchCustomerAccount}
                    onChange={(e) => handleSearchCustomerAccount(e.target.value)}
                    className="search-field"
                />
            </div>

            <div className="table-container">
                <Table
                    dataSource={filteredProduct}
                    columns={columns}
                    scroll={{ x: true, y: 380 }}
                    pagination={false}
                    onRow={(record) => ({
                        onClick: () => onRowClick(record),
                    })}
                />
            </div>
            <div className="modal-container">
                <Modal
                    title="Customer Amount Limit"
                    open={isModalVisible}
                    onOk={handleModalSubmit}
                    onCancel={handleModalCancel}
                    okText="Save"
                    cancelText="Cancel"
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="username"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="amount_limit"
                            rules={[
                                { required: true, message: 'Please Enter Amount Limit.' },
                            ]}
                        >
                            <Input
                                placeholder="Amount Limit"
                            />
                        </Form.Item>

                        <Form.Item
                            name="amount"
                        >
                            <Input disabled />
                        </Form.Item>

                    </Form>

                </Modal>
            </div>
        </div>
    );
};

export default ViewCustomerAccountLimitList;