import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/login";
import { useSelector } from "react-redux";
import Register from "../components/register";
import MyComponent from "../components/test";
import HomePage from "../pages/home";
import ProductPage from "../pages/product/product";
import CreateProductPage from "../pages/product/createproduct";
import SalesPage from "../pages/sales/sales";
import BillPage from "../pages/sales/bill";
import CreateCategoryPage from "../pages/category/createCategory";
import CategoryPage from "../pages/category/category";
import CreateStylePage from "../pages/style/createStyle";
import StylePage from "../pages/style/style";
import CreateStyleCategoryPage from "../pages/stylecategory/createStyleCategory";
import StyleCategoryPage from "../pages/stylecategory/styleCategory";
import ChequePage from "../pages/cheque/cheque";
import CustomerPage from "../pages/customer/customer";
import CreateCustomerPage from "../pages/customer/createCustomer";
import CreateProductTransportPage from "../pages/product_transport/createproduct_transport.jsx";
import ProductTransportPage from "../pages/product_transport/product_transport.jsx";
import ProductReceivedPage from "../pages/product_received/product_received.jsx";
import ProductReceivedAdminApprovePage from "../pages/product_received/product_received_admin_approve.jsx";
import ProductReceivedAllPage from "../pages/product_received/product_received_all.jsx";
import AllCustomerAccountPage from "../pages/customer_account/all_customer_account.jsx";
import AllCustomerAccountAmountLimitPage from "../pages/customer_account/all_customer_accountAmountLimit.jsx";
import ReturnChequePage from "../pages/cheque/return_cheque.jsx";
import CreateSupplierPage from "../pages/supplier/createSupplier.jsx";
import SupplierPage from "../pages/supplier/supplier.jsx";
import CreateSupplierAccessoriesPage from "../pages/supplierAccessories/createSupplier.jsx";
import SupplierLastBillPage from "../pages/supplierAccessories/supplier.jsx";
import SupplierPaymentListPage from "../pages/supplierAccessories/supplierPaymentLst.jsx";
import ViewSupplierAccessoriesBillPage from "../pages/supplierAccessories/viewSupplierAccessoriesBill.jsx";
import CreateSupplierFabricPage from "../pages/supplierFabric/createSupplier.jsx";
import ViewSupplierFabricBillPage from "../pages/supplierFabric/viewSupplierAccessoriesBill.jsx";
import SupplierFabricLastBillPage from "../pages/supplierFabric/supplier.jsx";
import SupplierFabricPaymentListPage from "../pages/supplierFabric/supplierPaymentLst.jsx";

const RoutesPage = () => {
    const token = useSelector((state) => state.auth.token);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/home" element={token !== null ? <HomePage /> : <Navigate to="/" />} />
                    <Route path="/style" element={token !== null ? <CreateStylePage /> : <Navigate to="/" />} />
                    <Route path="/allstyle" element={token !== null ? <StylePage /> : <Navigate to="/" />} />
                    <Route path="/category" element={token !== null ? <CreateCategoryPage /> : <Navigate to="/" />} />
                    <Route path="/allcategory" element={token !== null ? <CategoryPage /> : <Navigate to="/" />} />
                    <Route path="/style_category" element={token !== null ? <CreateStyleCategoryPage /> : <Navigate to="/" />} />
                    <Route path="/allstyle_category" element={token !== null ? <StyleCategoryPage /> : <Navigate to="/" />} />
                    <Route path="/product" element={token !== null ? <CreateProductPage /> : <Navigate to="/" />} />
                    <Route path="/allproduct" element={token !== null ? <ProductPage /> : <Navigate to="/" />} />
                    {/**create cheque  */}
                    
                    <Route path="/allcheque" element={token !== null ? <ChequePage /> : <Navigate to="/" />} />
                    <Route path="/chequeReturn" element={token !== null ? <ReturnChequePage /> : <Navigate to="/" />} />
                    
                    <Route path="/sales" element={token !== null ? <SalesPage /> : <Navigate to="/" />} />
                    <Route path="/salesBill" element={token !== null ? <BillPage /> : <Navigate to="/" />} />


                    <Route path="/customer" element={token !== null ? <CreateCustomerPage /> : <Navigate to="/" />} />
                    <Route path="/allcustomer" element={token !== null ? <CustomerPage /> : <Navigate to="/" />} />

                    <Route path="/product_transport" element={token !== null ? <CreateProductTransportPage /> : <Navigate to="/" />} />
                    <Route path="/allproduct_transport" element={token !== null ? <ProductTransportPage /> : <Navigate to="/" />} />

                    <Route path="/product_received" element={token !== null ? <ProductReceivedPage /> : <Navigate to="/" />} />
                    <Route path="/product_received_admin_Approve" element={token !== null ? <ProductReceivedAdminApprovePage /> : <Navigate to="/" />} />
                    <Route path="/product_received_all" element={token !== null ? <ProductReceivedAllPage /> : <Navigate to="/" />} />
                    
                    <Route path="/allcustomer_account" element={token !== null ? <AllCustomerAccountPage /> : <Navigate to="/" />} />
                    <Route path="/customer_account_limit" element={token !== null ? <AllCustomerAccountAmountLimitPage /> : <Navigate to="/" />} />
                    
                    <Route path="/supplier" element={token !== null ? <CreateSupplierPage /> : <Navigate to="/" />} />
                    <Route path="/allsupplier" element={token !== null ? <SupplierPage /> : <Navigate to="/" />} />
                    
                    <Route path="/supplier_accessories" element={token !== null ? <CreateSupplierAccessoriesPage /> : <Navigate to="/" />} />
                    <Route path="/supplier_accessories_last_bill" element={token !== null ? <SupplierLastBillPage /> : <Navigate to="/" />} />
                    <Route path="/supplier_accessories_paymentList" element={token !== null ? <SupplierPaymentListPage /> : <Navigate to="/" />} />
                    <Route path="/supplier_accessories_bill" element={token !== null ? <ViewSupplierAccessoriesBillPage /> : <Navigate to="/" />} />
                                        
                    <Route path="/supplier_fabric" element={token !== null ? <CreateSupplierFabricPage /> : <Navigate to="/" />} />
                    <Route path="/supplier_fabric_last_bill" element={token !== null ? <SupplierFabricLastBillPage /> : <Navigate to="/" />} />
                    <Route path="/supplier_fabric_paymentList" element={token !== null ? <SupplierFabricPaymentListPage /> : <Navigate to="/" />} />
                    <Route path="/supplier_fabric_bill" element={token !== null ? <ViewSupplierFabricBillPage /> : <Navigate to="/" />} />
                                        
                    <Route path="/test" element={token !== null ? <MyComponent /> : <Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default RoutesPage;