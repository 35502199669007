import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Table } from "antd";
import { deleteStyleCategory, getStyleCategory, updateStyleCategory } from "../../store/api/style_category";
import { DeleteOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import { getCategory } from "../../store/api/category";
import { getStyles } from "../../store/api/style";
import Search from "antd/es/input/Search";

const ViewStyleCategory = () => {
    const [form] = Form.useForm();
    const [styleCategory, setStyleCategory] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStyleCategory, setSelectedStyleCategory] = useState(null);
    const [modalVisibleCategory, setModalVisibleCategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [modalVisibleStyle, setModalVisibleStyle] = useState(false);
    const [styles, setStyles] = useState([]);
    const [selectedStyles, setSelectedStyles] = useState(0);
    const [search, setSearch] = useState('');

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchStyleCategory();
    }, []);

    const fetchStyleCategory = async () => {
        try {
            const response = await getStyleCategory();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const styleCategoryData = response.data.data.map((item) => ({
                        ...item,
                        key: item.sc_id,
                    }));
                    setStyleCategory(styleCategoryData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setStyleCategory([]);
                }
            } else {
                console.error('Failed to fetch style category');
            }
        } catch (error) {
            console.error('Error fetching style category:', error);
        }
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedStyleCategory(null);
        setIsModalVisible(false);
    };
    /*
        const handleEdit = (styleCategory) => {
            setSelectedStyleCategory(styleCategory);
            setIsModalVisible(true);
            form.setFieldsValue(styleCategory);
        };
    */

    const handleDelete = async (styleCategory) => {
        try {
            const response = await deleteStyleCategory(styleCategory.sc_id);

            if (response.status === 200) {
                setStyleCategory((prevStyleCategory) =>
                    prevStyleCategory.filter((item) => item.sc_id !== styleCategory.sc_id)
                );
            } else {
                console.error('Failed to delete style category');
            }
        } catch (error) {
            console.error('Error deleting style category:', error);
        }
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateStyleCategory(selectedStyleCategory.sc_id, values);

            if (response.status === 200) {
                setStyleCategory((prevStyleCategory) =>
                    prevStyleCategory.map((item) =>
                        item.sc_id === selectedStyleCategory.sc_id ? { ...item, ...values } : item
                    )
                );
                form.resetFields();
                setSelectedStyleCategory(null);
                setIsModalVisible(false);
                window.location.reload();
            } else {
                console.error('Failed to update style category');
            }
        } catch (error) {
            console.error('Error updating style category:', error);
        }
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Style',
            dataIndex: 'style',
            key: 'style',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, styleCategory) => (
                <div>
                    {/** <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(styleCategory)}
                    /> */}
                    <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(styleCategory)}
                    />
                </div>
            ),
        },
    ];

    const onRowClick = (record) => {
        //const categoryId = record.sc_id;
        // navigateTo(`/tournament?categoryId=${categoryId}`);
    };

    useEffect(() => {
        fetchCategories();
        fetchStyles();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getCategory();

            if (response.status === 200) {
                setCategories(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching categories", error);
        }
    };

    const handleCategorySelect = (c_id) => {
        setSelectedCategory(c_id);
        setModalVisibleCategory(false);
        form.setFieldsValue({ category: c_id });
        console.log(selectedCategory);
    };

    const categoryColumns = [
        {
            title: "Category ID",
            dataIndex: "c_id",
            key: "c_id",
        },
        {
            title: "Category",
            dataIndex: "name",
            key: "name",
        },
    ];

    const onRowClickCategory = (record) => {
        const categoryId = record.c_id;
        handleCategorySelect(categoryId);
        console.log(selectedStyles);
    };

    const fetchStyles = async () => {
        try {
            const response = await getStyles();

            if (response.status === 200) {
                setStyles(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching styles", error);
        }
    };

    const handleStyleSelect = (s_id) => {
        setSelectedStyles(s_id);
        setModalVisibleStyle(false);
        form.setFieldsValue({ style: s_id });
    };

    const styleColumns = [
        {
            title: "Style ID",
            dataIndex: "s_id",
            key: "s_id",
        },
        {
            title: "Style",
            dataIndex: "name",
            key: "name",
        },
    ];

    const onRowClickStyle = (record) => {
        const styleId = record.s_id;
        handleStyleSelect(styleId);
    };

    const handleSearch = (value) => {
        setSearch(value);
    };

    const filteredSearch = styleCategory.filter(item =>
        item.category.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Category"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredSearch}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ pageSize: 5 }}
                onRow={(record) => ({
                    onClick: () => onRowClick(record),
                })}
            />

            <Modal
                title="Style Category Details"
                open={isModalVisible}
                onOk={handleModalSubmit}
                onCancel={handleModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">

                    <Form.Item
                        name="sc_id"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => setModalVisibleCategory(true)}>Category</Button>
                    </Form.Item>

                    <Form.Item
                        name="category"
                        label="Category"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => setModalVisibleStyle(true)}>Style</Button>
                    </Form.Item>

                    <Form.Item
                        name="style"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>

                    <Form.Item
                        name="qty"
                        label="Quantity"
                        rules={[
                            { required: true, message: 'Please Enter Quantity.' },
                        ]}
                    >
                        <Input
                            placeholder="Quantity"
                        />
                    </Form.Item>

                    <Modal
                        title="Select a Category"
                        open={modalVisibleCategory}
                        onCancel={() => setModalVisibleCategory(false)}
                        footer={null}
                    >
                        <Table
                            columns={categoryColumns}
                            dataSource={categories}
                            rowKey="c_id"
                            onRow={(record) => ({
                                onClick: () => onRowClickCategory(record),
                            })}
                        />
                    </Modal>


                    <Modal
                        title="Select a Style"
                        open={modalVisibleStyle}
                        onCancel={() => setModalVisibleStyle(false)}
                        footer={null}
                    >
                        <Table
                            columns={styleColumns}
                            dataSource={styles}
                            scroll={{ x: true }}
                            pagination={false}
                            rowKey="s_id"
                            onRow={(record) => ({
                                onClick: () => onRowClickStyle(record),
                            })}
                        />
                    </Modal>

                </Form>
            </Modal>
        </div>
    );
};

export default ViewStyleCategory;