import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";
import store from "../selectors/store";

export const createProduct_Transport = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.PRODUCT_TRANSPORT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error;
    }
};

export const getProduct_Transport = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.ALLPRODUCT_TRANSPORT}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const updateProduct_Transport = async (product_transportId, updatedData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.PRODUCT_TRANSPORT}/${product_transportId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
            body: JSON.stringify(updatedData),
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const deleteProduct_Transport = async (product_transportId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.PRODUCT_TRANSPORT}/${product_transportId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};