import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Modal, Progress, Table } from "antd";
import { createProduct } from "../../store/api/product";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import { useSelector } from "react-redux";
import { getCategory } from "../../store/api/category";

const CreateProduct = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 
    const [modalVisibleCategory, setModalVisibleCategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const handleSubmit = async (values) => {
        try {
            const { category, r_price, h_price, color, description } = values;
            if (!category || !r_price || !h_price || !color || !description) {
                setError('Enter Valid Values');
                return;
            }

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const response = await createProduct({
                category: selectedCategory,
                r_price,
                h_price,
                color,
                description,
                pro_status: false
            }, token);

            if (response.status === 200) {
                setMessage(response.data.message);
                form.resetFields();
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);
                    setProgress(0);

                    navigateTo('/allproduct');

                }, 2000);
            } else {
                setProgress(0);
                setError(response.data.message);
            }

        } catch (error) {
            setError('An error occurred during style.');
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getCategory();

            if (response.status === 200) {
                setCategories(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching categories", error);
        }
    };

    const handleCategorySelect = (c_id) => {
        setSelectedCategory(c_id);
        setModalVisibleCategory(false);
        form.setFieldsValue({ category: c_id });
    };

    const categoryColumns = [
        {
            title: "Category ID",
            dataIndex: "c_id",
            key: "c_id",
        },
        {
            title: "Category",
            dataIndex: "name",
            key: "name",
        },
    ];

    const onRowClickCategory = (record) => {
        const categoryId = record.c_id;
        handleCategorySelect(categoryId);
    };

    return (
        <div className="user-form">
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item>
                    <Button onClick={() => setModalVisibleCategory(true)}>Category</Button>
                </Form.Item>
                <Form.Item
                    name="category"
                >
                    <Input
                        disabled
                    />
                </Form.Item>

                <Form.Item
                    name="r_price"
                    rules={
                        [
                            { required: true, message: "Please Enter Retail Price" }
                        ]
                    }
                >
                    <Input
                        placeholder="Retail Price (1999.99)"
                    />
                </Form.Item>

                <Form.Item
                    name="h_price"
                    rules={
                        [
                            { required: true, message: "Please Enter Wholesale Price" }
                        ]
                    }
                >
                    <Input
                        placeholder="Wholesale Price (1999.99)"
                    />
                </Form.Item>

                <Form.Item
                    name="color"
                    rules={
                        [
                            { required: true, message: "Please Enter color" }
                        ]
                    }
                    initialValue="-"
                >
                    <Input
                        placeholder="Color"
                    />
                </Form.Item>

                <Form.Item
                    name="description"
                    rules={
                        [
                            { required: true, message: "Please Enter Description" }
                        ]
                    }
                    initialValue="-"
                >
                    <Input
                        placeholder="Description"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

                {/* Progress bar */}
                <Progress percent={progress} />

                {/* Success Message */}
                {error && <Alert message={error} type="error" />}
                {successMessage && (
                    <Alert
                        className='alert-message'
                        message={Message}
                        type="success"
                        showIcon
                        icon={<CheckCircleOutlined className='alert-icon' />}
                    />
                )}

                <Modal
                    title="Select a Category"
                    open={modalVisibleCategory}
                    onCancel={() => setModalVisibleCategory(false)}
                    footer={null}
                >
                    <Table
                        columns={categoryColumns}
                        dataSource={categories}
                        rowKey="c_id"
                        onRow={(record) => ({
                            onClick: () => onRowClickCategory(record),
                        })}
                    />
                </Modal>

            </Form>
        </div>
    );
};

export default CreateProduct;