import React, { useState } from "react";
import { Alert, Button, Form, Input, Progress } from "antd";
import { createStyle } from "../../store/api/style";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import { useSelector } from "react-redux";

const Create = () => {
    const [form] = Form.useForm();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);// Success Message
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [progress, setProgress] = useState(0);// progress bar 

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const handleSubmit = async (values) => {
        try {
            const { name } = values;
            if (!name) {
                setError('Enter Valid Values');
                return;
            }

            const completedAttributes = Object.keys(values).filter(
                (key) => values[key]
            );
            const totalAttributes = Object.keys(values).length;
            const newProgress = (completedAttributes.length / totalAttributes) * 100;
            setProgress(newProgress);

            const response = await createStyle({
                name
            }, token);

            if (response.status === 200) {
                setMessage(response.data.message);
                form.resetFields();
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);
                    setProgress(0);

                    navigateTo('/allstyle');

                }, 2000);
            } else {
                setProgress(0);
                setError(response.data.message);
            }

        } catch (error) {
            setError('An error occurred during style.');
        }
    };

    return (
        <div className="user-form">
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                    name="name"
                    rules={
                        [
                            { required: true, message: "Please Enter Style Name" }
                        ]
                    }
                >
                    <Input
                        placeholder="Name"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>

                {/* Progress bar */}
                <Progress percent={progress} />

                {/* Success Message */}
                {error && <Alert message={error} type="error" />}
                {successMessage && (
                    <Alert
                        className='alert-message'
                        message={Message}
                        type="success"
                        showIcon
                        icon={<CheckCircleOutlined className='alert-icon' />}
                    />
                )}

            </Form>
        </div>
    );
};

export default Create;