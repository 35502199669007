import React, { useState } from "react";
import { Menu } from "antd";
import {
  UnorderedListOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTruck} from "@fortawesome/free-solid-svg-icons";
import Sider from "antd/es/layout/Sider";
import { Link } from "react-router-dom";
import logo from "../../images/product/logo.png";

const getMenu = (label, key, icon, children) => {
  return {
    label,
    key,
    icon,
    children,
  };
}

const items = [
  getMenu('Supplier', 'sub1', <FontAwesomeIcon icon={faTruck} />, [
    getMenu(<Link to='/allsupplier'>List</Link>, '1', <UnorderedListOutlined />),
    getMenu(<Link to='/supplier'>Create</Link>, '2', <PlusCircleOutlined />),
  ]),
  getMenu('Fabric Supplier', 'sub2', <FontAwesomeIcon icon={faTruck} />, [
    //getMenu(<Link to='/supplier_fabric_last_bill'>List</Link>, '3', <UnorderedListOutlined />),
    getMenu(<Link to='/supplier_fabric_paymentList'>Payment List</Link>, '4', <UnorderedListOutlined />),
    getMenu(<Link to='/supplier_fabric'>Create</Link>, '5', <PlusCircleOutlined />),
  ]),
  getMenu('Accessories Supplier', 'sub3', <FontAwesomeIcon icon={faTruck} />, [
    //getMenu(<Link to='/supplier_accessories_last_bill'>List</Link>, '3', <UnorderedListOutlined />),
    getMenu(<Link to='/supplier_accessories_paymentList'>Payment List</Link>, '6', <UnorderedListOutlined />),
    getMenu(<Link to='/supplier_accessories'>Create</Link>, '7', <PlusCircleOutlined />),
  ]),
];

const SupplierSideBar = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ paddingTop: '15px' }}>
      <div className="demo-logo-vertical">
        <div className="logo">
          <img alt="Logo" src={logo} style={{ paddingLeft: '20px' }} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={items}
        />
      </div>
    </Sider>
  )
};

export default SupplierSideBar;