import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";
import store from "../selectors/store";

export const createStyle = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.STYLE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error;
    }
};

export const getStyles = async () => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.AllSTYLE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Access the login token from the Redux store
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const updateStyle = async (styleId, updatedData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.STYLE}/${styleId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
            body: JSON.stringify(updatedData),
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};

export const deleteStyle = async (styleId) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.STYLE}/${styleId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getState().auth.token}`, // Authentication
            },
        });

        const status = response.status;
        const data = await response.json();

        return { status, data };
    } catch (error) {
        throw error;
    }
};