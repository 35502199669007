import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getSupplierFabricPayment } from "../../store/api/supplier_fabric_payment";
import '../../styles/default.scss';
import Search from "antd/es/input/Search";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const View = () => {
    const [fabricSupplierPayment, setFabricSupplierPayment] = useState([]);
    const [search, setSearch] = useState('');

    const navigateTo = useNavigate();

    useEffect(() => {
        fetchFabricSupplierPayment();
    }, []);

    const fetchFabricSupplierPayment = async () => {
        try {
            const response = await getSupplierFabricPayment();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const fabricSupplierPaymentData = response.data.data.map((item) => ({
                        ...item,
                        key: item.sup_pay_id,
                        date: moment(item.ch_date).format("YYYY-MM-DD"),
                        total: item.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    }));
                    setFabricSupplierPayment(fabricSupplierPaymentData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setFabricSupplierPayment([]);
                }
            } else {
                console.error('Failed to fetch style');
            }
        } catch (error) {
            console.error('Error fetching style:', error);
        }
    };

    const columns = [
        {
            title: 'Bill #',
            dataIndex: 'bill_no',
            key: 'bill_no',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Supplier',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const onRowClick = (record) => {
        const billNo = record.bill_no;
        navigateTo(`/supplier_fabric_bill?bill_no=${billNo}`);
    };

    const handleSearch = (value) => {
        setSearch(value);
    };

    const filteredSearch = fabricSupplierPayment.filter(item =>
        item.bill_no.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">
            <div className="search-container">
                <Search
                    placeholder="Search By Bill No"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredSearch}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ pageSize: 6 }}
                onRow={(record) => ({
                    onClick: () => onRowClick(record),
                })}
            />

        </div>
    );
};

export default View;