import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Table } from "antd";
import { deleteStyle, getStyles, updateStyle } from "../../store/api/style";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import '../../styles/default.scss';
import Search from "antd/es/input/Search";

const View = () => {
    const [form] = Form.useForm();
    const [style, setStyle] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStyle, setSelectedStyle] = useState(null);
    const [search, setSearch] = useState('');

    //const navigateTo = useNavigate();

    useEffect(() => {
        fetchStyle();
    }, []);

    const fetchStyle = async () => {
        try {
            const response = await getStyles();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const styleData = response.data.data.map((item) => ({
                        ...item,
                        key: item.s_id,
                    }));
                    setStyle(styleData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setStyle([]);
                }
            } else {
                console.error('Failed to fetch style');
            }
        } catch (error) {
            console.error('Error fetching style:', error);
        }
    };

    const handleModalCancel = () => {
        form.resetFields();
        setSelectedStyle(null);
        setIsModalVisible(false);
    };

    const handleEdit = (style) => {
        setSelectedStyle(style);
        setIsModalVisible(true);
        form.setFieldsValue(style);
    };

    const handleDelete = async (style) => {
        try {
            const response = await deleteStyle(style.s_id);

            if (response.status === 200) {
                setStyle((prevStyle) =>
                    prevStyle.filter((item) => item.s_id !== style.s_id)
                );
            } else {
                console.error('Failed to delete style');
            }
        } catch (error) {
            console.error('Error deleting style:', error);
        }
    };

    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateStyle(selectedStyle.s_id, values);

            if (response.status === 200) {
                setStyle((prevStyle) =>
                    prevStyle.map((item) =>
                        item.s_id === selectedStyle.s_id ? { ...item, ...values } : item
                    )
                );

                form.resetFields();
                setSelectedStyle(null);
                setIsModalVisible(false);
            } else {
                console.error('Failed to update style');
            }
        } catch (error) {
            console.error('Error updating style:', error);
        }
    };

    const columns = [
        {
            title: 'Style',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, style) => (
                <div>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(style)}
                    />
                    <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(style)}
                    />
                </div>
            ),
        },
    ];

    const onRowClick = (record) => {
        //const styleId = record.s_id;
        // navigateTo(`/tournament?styleId=${styleId}`);
    };
    
    const handleSearch = (value) => {
        setSearch(value);
    };

    const filteredSearch = style.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="user-form">
             <div className="search-container">
                <Search
                    placeholder="Search By Style"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="search-field"
                />
            </div>

            <Table
                dataSource={filteredSearch}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ pageSize: 5 }}
                onRow={(record) => ({
                    onClick: () => onRowClick(record),
                })}
            />

            <Modal
                title="Style Details"
                open={isModalVisible}
                onOk={handleModalSubmit}
                onCancel={handleModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">

                    <Form.Item
                        name="s_id"
                    >
                        <Input
                            disabled
                        />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="Style Name"
                        rules={[
                            { required: true, message: 'Please Enter Name.' },
                        ]}
                    >
                        <Input
                            placeholder="Style Name"
                        />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default View;