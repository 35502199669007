import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Modal, Table } from "antd";
import '../../styles/default.scss';
import { getSupplierAccessoriesById, getSupplierAccessoriesLastBillNo } from "../../store/api/supplier_accessories";
import Search from "antd/es/input/Search";
import { getSupplier, updateSupplier } from "../../store/api/supplier";
import { createSupplierAccessoriesPayment } from '../../store/api/supplier_accessories_payment';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';

const CreateSupplierAccessoriesBill = () => {
    const [supplierAccessories, setSupplierAccessories] = useState([]);
    const [billNo, setBillNo] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalAmountFormat, setTotalAmountFormat] = useState('');//this is for ui total format only
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [supplier, setSupplier] = useState([]);
    const [searchSupplier, setSearchSupplier] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [SupplierButtonClicked, setSupplierButtonClicked] = useState(false);
    const [SupplierName, setSupplierName] = useState('');
    const [error, setError] = useState('');
    const [Message, setMessage] = useState('');// Success Message from Backend 
    const [successMessage, setSuccessMessage] = useState(false);// Success Message

    const navigateTo = useNavigate();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        fetchSupplierAccessoriesLastBillNo();
        fetchSupplierAccessories(billNo);

        if (SupplierButtonClicked === true) {
            fetchSupplier();
            setSupplierButtonClicked(false);
        }
    }, [billNo, SupplierButtonClicked]);

    useEffect(() => {
        // Calculate total amount when supplier data changes
        const total = supplierAccessories.reduce((acc, item) => acc + item.amount, 0);
        const totalFormat = total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        setTotalAmountFormat(totalFormat);
        setTotalAmount(total);
    }, [supplierAccessories]);

    const fetchSupplierAccessories = async (billNo) => {
        try {
            if (billNo !== null) {
                const response = await getSupplierAccessoriesById(billNo);
                if (response.status === 200) {
                    if (Array.isArray(response.data.data)) {
                        const supplierData = response.data.data.map((item) => ({
                            ...item,
                            key: item.sup_acc_id,
                            u_price: item.u_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                           // amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        }));
                        setSupplierAccessories(supplierData);
                    } else {
                        console.error('API response data is not an array:', response.data);
                        setSupplierAccessories([]);
                    }
                } else {
                    console.error('Failed to fetch supplier');
                }
            }
        } catch (error) {
            console.error('Error fetching supplier:', error);
        }
    };

    const fetchSupplierAccessoriesLastBillNo = async () => {
        try {
            const response = await getSupplierAccessoriesLastBillNo();
            if (response.status === 200) {
                const supplierData = response.data.data.bill_no;
                setBillNo(supplierData);
            } else {
                console.error('Failed to fetch supplier');
            }
        } catch (error) {
            console.error('Error fetching supplier:', error);
        }
    };

    const fetchSupplier = async () => {
        try {
            const response = await getSupplier();
            if (response.status === 200) {
                if (Array.isArray(response.data.data)) {
                    const supplierData = response.data.data.map((item) => ({
                        ...item,
                        key: item.sup_id,
                        amount: item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    }));
                    setSupplier(supplierData);
                } else {
                    console.error('API response data is not an array:', response.data);
                    setSupplier([]);
                }
            } else {
                console.error('Failed to fetch supplier');
            }
        } catch (error) {
            console.error('Error fetching supplier:', error);
        }
    };

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Price',
            dataIndex: 'u_price',
            key: 'u_price',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const handleSearchSupplier = (value) => {
        setSearchSupplier(value);
    };

    const filteredSupplier = supplier.filter(item =>
        item.name.toLowerCase().includes(searchSupplier.toLowerCase())
    );

    const onRowClick = (record) => {
        setSupplierId(record.sup_id);
        setSupplierName(record.name);
        setIsModalVisible(false);
    }

    const supplierColumns = [
        {
            title: 'Supplier',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Shop',
            dataIndex: 'shop',
            key: 'shop',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const handleSupplier = () => {
        setIsModalVisible(true);
        setSupplierButtonClicked(true);
    }

    const handleSubmit = async () => {
        //  createSupplierAccessoriesPayment

        try {
            if (!billNo || !totalAmount || !supplierId) {
                setError('Enter Valid Values');
                return;
            }

            const response = await createSupplierAccessoriesPayment({
                bill_no: billNo,
                sup_id: supplierId,
                total: totalAmount,
            }, token);

            const updatedData = {
                amount: totalAmount,
            };
            const responseUpdateAmount = await updateSupplier(supplierId, updatedData,)

            if (response.status === 200 && responseUpdateAmount.status === 200) {
                setMessage(response.data.message);
                setError('');
                setSuccessMessage(true);

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage(false);

                    navigateTo('/supplier_accessories_paymentList');

                }, 2000);
            } else {
                setError(response.data.message);
            }

        } catch (error) {
            console.error('An error occurred during supplier accessories:', error);
        }
    }

    return (
        <div className="user-form">

            <Form.Item
                name="bill_no"
            >
                <Input placeholder={billNo}
                    disabled
                />
            </Form.Item>

            <Table
                dataSource={supplierAccessories}
                columns={columns}
                scroll={{ x: true, y: 200 }}
                pagination={false}
            />
            <br />
            <Form.Item
                name="total"
            >
                <Input placeholder={totalAmountFormat}
                    disabled
                />
            </Form.Item>

            <Button onClick={handleSupplier}>Supplier</Button>

            <div className="modal-container">
                <Modal
                    title="Supplier"
                    open={isModalVisible}
                    onCancel={() => {
                        setIsModalVisible(false)
                    }}
                    footer={false}
                >
                    <Search
                        placeholder="Search"
                        value={searchSupplier}
                        onChange={(e) => handleSearchSupplier(e.target.value)}
                        className="search-field"
                    />

                    <Table
                        columns={supplierColumns}
                        dataSource={filteredSupplier}
                        pagination={false}
                        scroll={{ y: 320 }}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                    />

                </Modal>
            </div>

            <Form.Item
                name="supplier"
            >
                <Input placeholder={SupplierName}
                    disabled
                />
            </Form.Item>

            <Button onClick={() => handleSubmit()}>Submit</Button>

            {error && <Alert message={error} type="error" />}
            {successMessage && (
                <Alert
                    className='alert-message'
                    message={Message}
                    type="success"
                    showIcon
                    icon={<CheckCircleOutlined className='alert-icon' />}
                />
            )}
        </div>
    );
};

export default CreateSupplierAccessoriesBill;