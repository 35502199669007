import configs from "../../configs/config";
import { API_ENDPOINT } from "../endpoint/endPoint";

export const loginUser = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.USER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error; 
    }
};

export const registerUser = async (userData) => {
    try {
        const response = await fetch(`${configs.localAPi}${API_ENDPOINT.REGISTER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const status = response.status;
        const data = await response.json();
        return { status, data }; // Return the API response
    } catch (error) {
        throw error; 
    }
};
